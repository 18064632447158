<template>
  <div>
    <div v-if="priceType === 'FREE'">{{ $t("Free") }}</div>
    <div v-else>
      <template v-if="priceType === 'FROM'">
        <span class="opacity-6 text-md">{{ $t("From")}} </span>
        <br>
      </template>
      <template v-if="(specialPrice != null)">
        <span> €{{specialPrice}}</span>
        <br>
      </template>

                          <span :style="(specialPrice != null) ? 'text-decoration: line-through;': ''" :class="(specialPrice != null) ? 'opacity-6 text-md' : ''">
                             €{{ cost }}
                          </span>



    </div>
  </div>
</template>

<script>
export default {
  name: "ServicePrice",
  props: {
    specialPrice: {
      type: Number,
      default: null,
      description: "The discounted price.",
    },
    cost: {
      type: Number,
      default: 0,
      description: "The initial cost of service",
    },
    priceType: {
      type: String,
      default: "FIXED",
      description: "price type of service",
    },
  }
}
</script>

<style scoped>

</style>