<template>
  <keep-alive v-if="$store.state.user.loggedIn">
    <div>
      <base-header
          class="header pb-8 pt-5 pt-lg-3 d-flex align-items-center"
          style="
          min-height: 200px;
          background-image: url(img/theme/profile-cover.jpg);
          background-size: cover;
          background-position: center top;
        "
      >
        <!-- Mask -->
        <span class="mask bg-gradient-primary opacity-8"></span>
        <!-- Header container -->
        <div class="container-fluid d-flex align-items-center col-xl-8">
          <div class="row">
            <div class="">
              <base-button v-if="$router.options.history.state.back" hover type="link"
                           class="row  text-white text-lg mt-2" style="box-shadow:none!important;"
                           @click="$router.go(-1)">
                <i class="fa fa-chevron-left"></i> {{ $t("Back") }}
              </base-button>
              <h1 class=" display-2 text-white ">{{ $t("My Appointments") }}</h1>
              <p class="text-white m-0 font-weight-400">
                {{ $t("Here you can find all your upcoming and past appointments.") }}
              </p>
              <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
            </div>
          </div>
        </div>
      </base-header>

      <div class="container-fluid mt--7">
        <div class="row justify-content-md-center" style="width: 100%; margin: 0 !important;">

          <div class="col-xl-8 order-xl-2" style="padding: 0;">
            <!--            <div v-if="loading" style="position: absolute;height: 100%;width: 100%;left: 0;top: 0;text-align: center;padding-top: 20%;font-size: 30px;z-index: 10;">-->
            <!--                <span-->
            <!--                    style="position: absolute;top: 20%;"-->
            <!--                    class="fas fa-cog fa-spin"-->
            <!--                    role="status"-->
            <!--                    aria-hidden="true"-->
            <!--                ></span>-->
            <!--            </div>-->

            <div class="col-12 text-white" v-if="upcomingAppointments.length === 0 && pastAppointments.length === 0 && !loading">
              {{ $t("You currently have no appointments.") }}
            </div>

            <div v-if="upcomingAppointments.length > 0" class="row pl-2">
              <div class="col-12">
                <h1 class="text-white">{{ $t("Upcoming Appointments") }}</h1>
              </div>
            </div>
            <card v-for="appointment in upcomingAppointments" v-bind:key="appointment" class="m-2" shadow
                  type="secondary">

              <div class="row">
                <div class="col-md-8">
                  <a class="text-underline align-middle" type="link" :href="'/c/'+appointment.companyId">

                    <h2 class="d-inline" style="line-height: 20px;color: teal">{{ appointment.companyName }}</h2>
                    <i class="pl-2 fas fa-external-link-alt d-inline" style="color: teal;" ></i>
                  </a>
                </div>
                <div class="col-md-4 mobile-left-align text-right">
                  <badge
                      pill
                      :type="getStatusClass(appointment.status)"
                  >{{ $t(appointment.status) }}
                  </badge>
                </div>
              </div>
              <!--            <div class="row pb-2">-->
              <!--              <div class="col-md-12">-->
              <!--&lt;!&ndash;              <badge&ndash;&gt;-->
              <!--&lt;!&ndash;                  v-if="appointment.hasOwnProperty('loyaltyProgramEnable') && appointment.loyaltyProgramEnable"&ndash;&gt;-->
              <!--&lt;!&ndash;                  pill&ndash;&gt;-->
              <!--&lt;!&ndash;                  type="info"&ndash;&gt;-->
              <!--&lt;!&ndash;              ><i class="fa fa-users mr-1"></i> {{$t("Loyalty Points")}} {{appointment.hasOwnProperty('loyaltyPoints') ? appointment.loyaltyPoints : ''}}</badge>&ndash;&gt;-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="row ">


                <div class="col-md-6">

                  <table>
                    <tr>
                      <th style="min-width: 90px">{{ $t("Start time") }}:</th>
                      <td>{{ appointment.startTime ? appointment.startTime.format("DD MMM YYYY HH:mm a") : "" }}</td>
                    </tr>
                    <tr>
                      <th style="min-width: 80px">{{ $t("Employee") }}:</th>
                      <td>{{ appointment.employeeName }}</td>
                    </tr>
                    <!--                  <tr>-->
                    <!--                    <th style="min-width: 80px">Booked on:</th>-->
                    <!--                    <td>{{ appointment.mcreatedAt}}</td>-->
                    <!--                  </tr>-->
                  </table>
                </div>
                <div class="col-md-3">
                  <table>
                    <tr>
                      <th style="min-width: 80px">{{ $t("Cost") }}:</th>
                      <td>€<span
                          :style="appointment.redeemLoyaltyPoints ? 'text-decoration: line-through' : ''">{{ appointment.cost }} </span>
                        {{ appointment.redeemLoyaltyPoints ? "0" : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <badge
                            v-if="appointment.redeemLoyaltyPoints"
                            pill
                            type="info"
                        >{{ $t("Loyalty points redeemed!") }}
                        </badge>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("Duration") }}:</th>
                      <td>{{ convertMinsToHrsMins(appointment.duration, true) }}</td>
                    </tr>
                  </table>

                </div>

              </div>
              <div class="row">
                <div class="col-md-6 text-left pt-3 align-self-end">
                  <template v-if="appointment.type === 'MOBILE'">
                      <tooltip :tooltip-text="getLocationString(appointment.address)" position="top">
                        <i class="fa fa-home"></i> {{ $t("At your place") }}
                      </tooltip>
                      <div title="addressss">

                      </div>

                  </template>
                </div>
                <div class="col-md-6 text-right align-self-end">
                  <base-button class="mt-3" size="md" @click="goToAppointment(appointment)" type="success">
                    {{ $t("View details") }}
                  </base-button>

                </div>
              </div>
            </card>

            <div v-if="pastAppointments.length > 0" class="row  pt-5 pl-2">
              <div class="col-12">
                <h1 class="">{{ $t("Past Appointments") }}</h1>
              </div>

            </div>

            <card v-for="appointment in pastAppointments" v-bind:key="appointment" class="m-2" shadow type="secondary">

              <div class="row">
                <div class="col-md-8">
                  <a class="text-underline align-middle" type="link" :href="'/c/'+appointment.companyId">

                    <h2 class="d-inline" style="line-height: 20px;color: teal">{{ appointment.companyName }}</h2>
                    <i class="pl-2 fas fa-external-link-alt d-inline" style="color: teal;" ></i>
                  </a>
                </div>
                <div class="col-md-4 mobile-left-align text-right">
                  <badge
                      pill
                      :type="getStatusClass(appointment.status)"
                  >{{ $t(appointment.status) }}
                  </badge>
                </div>
              </div>
              <!--            <div class="row pb-2">-->
              <!--              <div class="col-md-12">-->
              <!--&lt;!&ndash;              <badge&ndash;&gt;-->
              <!--&lt;!&ndash;                  v-if="appointment.hasOwnProperty('loyaltyProgramEnable') && appointment.loyaltyProgramEnable"&ndash;&gt;-->
              <!--&lt;!&ndash;                  pill&ndash;&gt;-->
              <!--&lt;!&ndash;                  type="info"&ndash;&gt;-->
              <!--&lt;!&ndash;              ><i class="fa fa-users mr-1"></i> {{$t("Loyalty Points")}} {{appointment.hasOwnProperty('loyaltyPoints') ? appointment.loyaltyPoints : ''}}</badge>&ndash;&gt;-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="row ">
                <div class="col-md-6">

                  <table>
                    <tr>
                      <th style="min-width: 90px">{{ $t("Start time") }}:</th>
                      <td>{{ appointment.startTime ? appointment.startTime.format("DD MMM YYYY HH:mm a") : "" }}</td>
                    </tr>
                    <tr>
                      <th style="min-width: 80px">{{ $t("Employee") }}:</th>
                      <td>{{ appointment.employeeName }}</td>
                    </tr>
                    <!--                  <tr>-->
                    <!--                    <th style="min-width: 80px">Booked on:</th>-->
                    <!--                    <td>{{ appointment.mcreatedAt}}</td>-->
                    <!--                  </tr>-->
                  </table>
                </div>
                <div class="col-md-6">
                  <table>
                    <tr>
                      <th style="min-width: 80px">{{ $t("Cost") }}:</th>
                      <td>€<span
                          :style="appointment.redeemLoyaltyPoints ? 'text-decoration: line-through' : ''">{{ appointment.cost }} </span>
                        {{ appointment.redeemLoyaltyPoints ? "0" : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <badge
                            v-if="appointment.redeemLoyaltyPoints"
                            pill
                            type="info"
                        >{{ $t("Loyalty points redeemed!") }}
                        </badge>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("Duration") }}:</th>
                      <td>{{ convertMinsToHrsMins(appointment.duration, true) }}</td>
                    </tr>
                  </table>

                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left pt-3 align-self-end">
                  <template v-if="appointment.type === 'MOBILE'">
                    <i class="fa fa-home"></i> {{ $t("At your place") }}
                  </template>
                </div>
                <div class="col-md-6 text-right align-self-end">
                  <base-button class="mt-3 mr-0" size="md" @click="$router.push({ path: '/quick-book/'+appointment.companyId})" type="primary" >{{$t("Book again")}}</base-button>

                  <base-button class="mt-3 mr-0 ml-1" size="md" @click="goToAppointment(appointment)" type="success">
                    {{ $t("View details") }}
                  </base-button>

                </div>
              </div>
            </card>


            <div class="text-center" v-if="!listFull">
              <base-button v-if="!loading" type="secondary" @click="getUserAppointments()">{{
                  $t("Load more")
                }}
              </base-button>
              <div v-else class="pt-2">
                <span
                    style="top: 20%;font-size: 25px;"
                    class="fas fa-cog fa-spin"
                    role="status"
                    aria-hidden="true"
                ></span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </keep-alive>
</template>

<script>
import {store} from "@/store";
import BaseButton from "@/components/BaseButton";
import moment from "moment";
import {convertMinsToHrsMins, getStatusClass, getLocationString} from "@/directives/helper";
import Tooltip from "@/views/Components/Tooltip";

export default {
  name: "UserAppointments",
  components: {Tooltip, BaseButton},
  data() {
    return {
      asdada: true,
      listFull: false,
      lastItem: null,
      loading: false,
      now: moment(),
      appointments: []
    }
  },
  async mounted() {
    console.log('Called when have mount')
    await this.getUserAppointments()
  },
  computed: {
    upcomingAppointments() {
      if (this.appointments.length === 0) {
        return []
      }
      let self = this
      return this.appointments.filter(function (el) {
        return el.startTime > self.now
      })
    },
    pastAppointments() {
      if (this.appointments.length === 0) {
        return []
      }

      let self = this
      return this.appointments.filter(function (el) {
        return el.startTime < self.now
      })

    }
  },
  methods: {
    getStatusClass,
    convertMinsToHrsMins,
    getLocationString,
    goToAppointment(appointment) {
      this.$router.push({name: "UserAppointment", params: {id: appointment.bookingRef}});

    },
    async getUserAppointments() {
      let self = this
      self.loading = true
      await store
          .dispatch("getUserAppointments", {lastItem: self.lastItem})
          .then((data) => {
            self.loading = false
            if (data.lastItem) {
              self.lastItem = data.lastItem
            } else {
              self.listFull = true
            }

            if (data.results.length < 10) {
              self.listFull = true
            }
            data.results.forEach(item => {
              console.log("items", item)
              let index = self.appointments.indexOf(s => s.id === item.id)
              if (index === -1) {
                self.appointments.push(item)
              } else {
                self.appointments[index] = item
              }
            })
          })
    }
  }
}
</script>

<style scoped>

</style>