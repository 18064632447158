<template>
  <div
    class="custom-control custom-checkbox"
    :class="[{'form-check-inline': inline },
          { 'has-success': valid === true },
      { 'has-danger': valid === false }]"
  >
    <input
      :id="cbId"
      class="custom-control-input"
      :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
        ]"
      type="checkbox"

      v-model="model"
    />
    {{model}}
    <label :for="cbId" class="custom-control-label">
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";

export default {
  name: "base-checkbox",
  model: {
    prop: "checked",
  },
  props: {
    checked: {
      type: Boolean,
      description: "Whether checkbox is checked",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("input", check);
      },
    },
  },
  mounted() {
    this.cbId = randomString();
  },
};
</script>

<style>
.custom-control-label::before {
  right: -30px;
  left: unset !important;
}

.custom-control-label::after {
  right: -30px;
  left: unset !important;
}
label.custom-control-label {
  right: 30px;
}
.custom-control-label::after {
  border: 1px solid red;
  border-radius: 3px;
}
</style>