<template>
  <div>

  <vueper-slides
      :fixed-height="height"
  >
    <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image">
    </vueper-slide>
  </vueper-slides>

  </div>
</template>

<script>
import 'vueperslides/dist/vueperslides.css'
import {VueperSlide, VueperSlides} from "vueperslides";

export default {
  name: "PhotoSlider",
  components: { VueperSlides, VueperSlide },
  props: {
    photos: {
      default: null,
      type: Array,
      description: "array of photos",
    },
    height: {
      default: "450px",
      type: String,
      description: "fixed height of slider",
    }
  },
  data: () => ({
    slides: []
  }),
  watch:{
    "photos": function (newVal) {
      this.genGallery(newVal)
    }
  },
  mounted() {
    this.genGallery()
  },
  methods: {
    genGallery(gallery) {
      this.slides = []
      let photos = []
      if (gallery) {
        photos = gallery
      } else if (this.photos) {
        photos = this.photos
      }
      photos.forEach(photo => {
        let slide = {
          image: photo,
          title: "Photo",
          content: "this is a photo"
        }
        this.slides.push(slide)
      })
    }
  }
}
</script>

<style scoped>

</style>