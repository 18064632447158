<template>
  <div>
    <div class="outterCarrusel">

      <Carousel v-model="currentSlide" ref="dateCarousel" :breakpoints="breakpoints" :settings="settings">
      <Slide v-for="date in dates" :key="date.date">
        <div>
        <div class="carousel__month">
          <div v-if="dates[currentSlide].date.format('DDMMYYYY') === date.date.format('DDMMYYYY')" >{{ date.date.format("MMMM") }}</div>
          <div v-else-if="date.date.format('DD') === '01'">{{ date.date.format("MMMM") }}</div>
        </div>
        <div class="carousel__item">
               <button
                   class="dateButton text-dark"
                   :class="{'active': isSame(selectedDate,date.date)}"
                   @click="selectDate(date)"
                   :disabled="disabledDates.includes(date.date.format('yyyyMMDD'))"
               >
                 <span class="text-muted">{{ date.date.format("ddd") }}</span> <br>
                 <span class="">{{ date.date.format("DD") }}</span>

          </button>
        </div>
        </div>
      </Slide>

      </Carousel>
    </div>
    <button @click="next()" type="button" class="carousel__next hide-on-mobile" aria-label="Navigate to next slide">
      <i class="fa fa-arrow-right"></i>
    </button>
    <button :disabled="currentSlide === 0 || currentSlide === null"  @click="prev()" type="button" class="carousel__prev hide-on-mobile" aria-label="Navigate to previous slide">
      <i class="fa fa-arrow-left"></i>
    </button>

  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import moment from "moment";
import { ref } from 'vue'
export default {
  name: "ScrollDatePicker",
  components: {
    Carousel,
    Slide,
  },
  props: {
    minDate: {
      type: Date,
      description: "Minimum date",
    },
    maxDate: {
      type: Date,
      description: "Max date",
    },
    selectedDate: {
      type: Object,
      description: "selected Date",
    },
    disabledDates: {
      type: Array,
      description: "Dates to disable",
    }
  },
  data: () => ({
    dates: [],
    breakpoints: {
      200: {
        itemsToShow: 4.5,
        itemsToScroll: 0,
        snapAlign: 'start',
      },
      // 700px and up
      500: {
        itemsToShow: 5,
        itemsToScroll: 5,
        snapAlign: 'start',
      },

      // 1024 and up
      1024: {
        itemsToShow: 5,
        itemsToScroll: 5,
        snapAlign: 'start',
      },
    },
    // carousel settings
    currentSlide: 0,
    settings: {
      itemsToShow: 5,
      itemsToScroll: 5,
      snapAlign: 'center',
    },
  }),
  setup() {
    const dateCarousel = ref(null)
    return { dateCarousel }
  },
  mounted(){
    this.getDates()
  },
  computed: {
  },
  methods: {
    selectDate(date){
      this.$emit('update:selectedDate', date.date)
      let index = this.dates.findIndex(d => d.date === date.date)
      this.currentSlide = index
    },
    next(){
      this.dateCarousel.next()
    },
    prev(){
      this.dateCarousel.prev()
    },
    isSame(date1, date2){
      return moment(date1).format("DDMMYYYY") === moment(date2).format("DDMMYYYY")
    },
    getDates(){
      let minDate = this.minDate ?? new Date()
      let maxDate = this.maxDate ?? moment().add(3, "months").toDate()
      for (let theDate = moment(minDate).locale(this.$store.state.locale); theDate.toDate() <= maxDate; theDate.add(1, "day")) {
        this.dates.push({date: theDate.clone(), enabled: true})
      }
    }
  }
}
</script>

<style scoped>
.outterCarrusel {
  width: calc(100% - 80px) !important;
  margin: 0 auto;
}
.carousel__item .dateButton{
  margin: 10px 0;
  padding: 4px 13px;
  border-radius: 7px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.15s ease;
  width: 80px;
}

.carousel__item .dateButton:hover {

  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  transform: translateY(-1px);
}

button.dateButton.active {
  background: linear-gradient(87deg, #00a4a4 0, teal 100%) !important;
  color:white !important;
}

.carousel__month {
  height: 24px;
  text-align: left;
  font-weight: 700;
  padding-left: 15px;
}
.dateButton.active span.text-muted {
  color: white !important;
}

.carousel__prev {
  left: 0;
  padding-top: 20px;
  -webkit-text-stroke: 2px white;
}

.carousel__prev:disabled{
  color: #66666675 !important;
}

.carousel__next {
  right: 0;
  padding-top: 20px;
  -webkit-text-stroke: 2px white;
}

.carousel__item .dateButton:disabled, .carousel__item .dateButton[disabled] {
  background-color: #cccccc8a !important;
  color: #666666 !important;
  transform: none !important;
  box-shadow: none !important;
}
@media (max-width:670px) {
  .outterCarrusel {
    width: 100% !important;
  }

  .carousel__item .dateButton{
    width: 70px;
  }
}

</style>