import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store";
import router from "./router";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-multiselect/dist/vue-multiselect.css"
import 'bootstrap/js/dist/collapse';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import i18n from "./lang/lang.js";
import moment from 'moment'
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
import { getAnalytics } from "firebase/analytics";
import '@vuepic/vue-datepicker/dist/main.css';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

let firebaseConfig;
if (process.env.NODE_ENV === "production") {
  firebaseConfig = require("../prodConfig.json");
} else {
  firebaseConfig = require("../devConfig.json");
}

export const fireApp = initializeApp(firebaseConfig);
export const firestore = getFirestore();

let site_key= ""
if (firebaseConfig.projectId === "appoint-me-233515"){
  site_key = "6Lfqn20eAAAAANy4QF6JCRdLNZ9-8WmESRaUctrC"
} else {
  site_key = "6Lc5APQeAAAAAKIJzUtWse0CThbTX20DlgmUsD0I"
}

initializeAppCheck(fireApp, {
  provider: new ReCaptchaV3Provider(site_key),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: false,
});

export const analytics = getAnalytics(fireApp);
export const auth = getAuth(fireApp);

onAuthStateChanged(auth, async (user) => {
  if (user == null && store.state.user.data !== null) {
    await store.dispatch("signOut");
  }

  if ((store.state.user.data == null || store.state.user.claims == null) && user !== null) {
    await store.dispatch("fetchUser", user);
    // eslint-disable-next-line no-prototype-builtins
  }
});

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.config.globalProperties.$moment = moment;
appInstance.use(moment);
appInstance.use(i18n);
appInstance.use(Toast);
appInstance.use(VueSweetalert2)
appInstance.component("kinesis-container", KinesisContainer);
appInstance.component("kinesis-element", KinesisElement);

const scrollableElementId = 'content' // You should change this
const scrollPositions = Object.create(null)

window.addEventListener('popstate', () => {
  let currentRouteName = router.currentRoute.value.name
  let element = document.getElementById(scrollableElementId)
  if (element !== null && currentRouteName in scrollPositions) {
    setTimeout(() => element.scrollTop = scrollPositions[currentRouteName], 50)
  }
})

router.beforeEach(async (to, from, next) => {
  let element = document.getElementById(scrollableElementId)
  if (element !== null) {
    scrollPositions[from.name] = element.scrollTop
  }

  let currentUser = await store.dispatch("isLoggedIn");
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let roleAccess = to.meta.role;
  if (!isAllowed() && currentUser) {
    next("error");
    return;
  }

  if (to.name === "QuickBook") {
    next();
  }

  if (to.name === "login" && currentUser && isAllowed()) {
    next("/");
  }

  if (to.name === "Admin Login" && currentUser && isAllowed()) {
    next("dashboard");
  }

  if ((requiresAuth && !currentUser) || !isAllowed()) next("login");
  else next();

  function isAllowed() {
    if (roleAccess == null || store.state.user.claims == null) {
      return true;
    }
    return store.state.user.claims.role === roleAccess;
  }
});
appInstance.config.devotools = true;
appInstance.use(ArgonDashboard);
appInstance.mount("#app");