<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-3 d-flex align-items-center"
        style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center col-xl-8">
        <div class="row">
          <div class="">
            <base-button v-if="$router.options.history.state.back" hover type="link" class="row  text-white text-lg mt-2" style="box-shadow:none!important;" @click="$router.go(-1)">
              <i class="fa fa-chevron-left"></i> {{ $t("Back") }}
            </base-button>
            <h1 v-if="appointment.mStartTime !== null" class=" display-2 text-white ">{{ appointment.mStartTime.format("dddd, DD MMM YYYY") }} <div class="new-line-on-mobile"> {{ appointment.mStartTime.format(" [at] HH:mm a") }}</div></h1>
            <p class="text-white m-0 font-weight-400">
              {{ $t("Booking Ref:")}} <b>{{appointment.bookingRef}}</b>
            </p>
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row justify-content-md-center">
        <div class="col-xl-8 order-xl-2" style="padding: 0;">
          <div v-if="loading" style="position: absolute;height: 100%;width: 100%;left: 0;top: 0;text-align: center;padding-top: 20%;font-size: 30px;z-index: 10;">
              <span
                  style="position: absolute;top: 20%;"
                  class="fas fa-cog fa-spin"
                  role="status"
                  aria-hidden="true"
              ></span>
          </div>
        <card v-else class="m-2" shadow type="secondary" >
          <template v-slot:header>
            <div class="bg-white border-0">
          <div class="row ">
            <div class="col-md-6">
              <div>

                <a class="text-underline pl-2 align-middle" type="link" :href="'/c/'+appointment.companyId">

                  <h2 class="d-inline" style="line-height: 20px;color: teal">{{ appointment.companyName }}</h2>
                  <i class="pl-2 fas fa-external-link-alt d-inline" style="color: teal;" ></i>
                </a>
              </div>
            </div>

            <div class="mobile-left-align col-md-6 text-right">
              <badge
                  class="badge-md"
                  style="font-size: 14px"
                  pill
                  :type="getStatusClass(appointment.status)"
              ><i :class="getStatusIconClass(appointment.status) +' pr-2'"></i><span  >{{ $t(appointment.status) }}</span></badge>
            </div>
          </div>
              <div class="row pt-3">
                <div class=" col-md-6 d-inline pr-2" v-if="appointment">

                  <td v-if="appointment.hasOwnProperty('address') && appointment.type === 'BUSINESS' && appointment.address.street !== null">
                    <a target="_blank" :href="'https://www.google.com/maps/dir//'+appointmentLocationSearch" class="pt-3" v-if="appointment">
                      <div class="d-inline-block"><i class="fa fa-map-marker"></i></div>
                      {{ appointment.address.street ?? "" }}
                      {{ appointment.address.street2 ?? "" }}
                      {{ appointment.address.postalCode ? ", " + appointment.address.postalCode : "" }}

                      {{ $t(appointment.address.city ?? "") }}
                      {{ appointment.address.country ? ", " + $t(appointment.address.country) : "" }}
                      <br>

                    </a>
                  </td>
<!--                  <td v-else>-->
<!--                    <template v-if="appointment.type === 'MOBILE'">-->
<!--                      <i class="fa fa-home"></i>-->
<!--                    </template>-->
<!--                  </td>-->
                </div>
                <div class="mobile-left-align col-md-6 text-right align-self-end pt-3">

                  <base-button v-if="appointment.mStartTime > now && appointment.status !== 'CANCELED'" class="" size="md" @click="openRescheduleModal()" type="primary" >{{$t("Reschedule")}}</base-button>
                  <base-button v-if="appointment.mStartTime > now && appointment.status !== 'CANCELED'" class="" size="md" @click="notifyCancelAppointment(appointment)" type="danger" >{{$t("Cancel")}}</base-button>
                  <base-button v-if="appointment.mStartTime < now || appointment.status === 'CANCELED'" class="mt-3" size="md" @click="$router.push({ path: '/quick-book/'+appointment.companyId})" type="primary" >{{$t("Book again")}}</base-button>

                </div>
              </div>

            </div>
          </template>
          <div class="row col-md-12 pb-2">
            <badge
                class="mr-2 mb-2"
                v-if="appointment.hasOwnProperty('loyaltyProgramEnable') && !appointment.redeemLoyaltyPoints && appointment.loyaltyProgramEnable && !(isPastAppointment && 'PENDING' === appointment.status) && 'CANCELED' !== appointment.status"
                pill
                type="info"
            ><i class="fa fa-users mr-1"></i> {{$t("Loyalty Points")}} {{appointment.hasOwnProperty('loyaltyPoints') ? (appointment.loyaltyPoints * 100).toFixed(0) : ''}}</badge>
            <badge
                v-if="appointment.redeemLoyaltyPoints"
                pill
                type="info"
                class="mr-2 mb-2"
            ><i class="fa fa-wine-bottle mr-1"></i> {{ $t("Loyalty points redeemed")}}!</badge>
            <badge
                class="mr-2 mb-2"
                v-if="appointment.hasOwnProperty('sendSMSNotification') && appointment.sendSMSNotification"
                pill
                type="info"
            ><i class="fa fa-envelope mr-1"></i> {{$t("SMS Reminder")}}</badge>
            <badge
                v-if="appointment.type === 'MOBILE'"
                pill
                type="info"
                class="mr-2 mb-2"
            ><i class="fa fa-home mr-1"></i> {{ $t("Remote Appointment")}}!</badge>

          </div>
          <div class="row">



              <div class="col-md-4 pb-2">
                <div class="form-control-label">{{ $t("Time")}}:</div>
                <div>
                  {{appointment.hasOwnProperty("mStartTime") && appointment.mStartTime != null ? appointment.mStartTime.format("HH:mm") : ""}}
                  -
                  {{appointment.hasOwnProperty("endTime") && appointment.endTime != null ? appointment.endTime.format("HH:mm") : ""}}

                </div>
              </div>
              <div class="col-md-4 pb-2">
                <div class="form-control-label" >{{ $t("Employee")}}:</div>
                <div>{{ appointment.employeeName}}</div>
              </div>
              <div class="col-md-4  pb-2">
                <div class="form-control-label" >{{ $t("Created On") }}:</div>
                <div>{{ appointment.createdAt}}</div>
              </div>
              <div  v-if="appointment.notes !== '' && appointment.notes !== null" class="col-md-8  pb-2">
                <div class="form-control-label" >{{ $t("Notes") }}:</div>
                <div>{{ appointment.notes}}</div>
              </div>



          </div>
<!--          <card class="m-2" shadow type="secondary" >-->
          <div class="row pb-3" v-if="appointment.address !== null & appointment.type === 'MOBILE'">
            <div class="col-md-12">
              <div class="form-control-label">{{ $t("Location") }}:</div>
              <div>
                <a target="_blank" :href="'https://www.google.com/maps/dir//'+appointmentLocationSearch" class="pt-3" v-if="appointment">
                  <div class="d-inline-block"><i class="fa fa-map-marker"></i></div>
                  {{ appointment.address.street ?? "" }}
                  {{ appointment.address.street2 ?? "" }}
                  {{ appointment.address.postalCode ? ", " + appointment.address.postalCode : "" }}

                  {{ $t(appointment.address.city ?? "") }}
                  {{ appointment.address.country ? ", " + $t(appointment.address.country) : "" }}
                  <br>

                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-control-label pb-2 pt-2">{{ $t("Services") }}:</div>
              <div >

                <div v-bind:key="service" class="row pb-3" v-for="(service, index) in appointment.services">
                  <div class="col-8">
                    <b class="text-sm ">{{ service.name }}</b> <br />
                    <span class="text-sm text-black-50">{{ convertMinsToHrsMins(service.duration) }}</span>
                  </div>

                  <div class="col-4 text-right">
                    <service-price :cost="service.cost" :price-type="service.priceType" :special-price="service.specialPrice"></service-price>
                  </div>
                  <hr class="col" v-if="(index+1) < appointment.services.length" style="width: 100%;margin: 15px 15px 0 15px;">
                </div>
                <hr style="width: 100%;border-top: 2px solid #8c8b8b;margin-bottom: 10px;margin-top: 0">
              </div>
<!--              <div class="row pb-2" v-if="appointment.type === 'MOBILE'">-->

<!--                  <div class="col-6">-->
<!--                    <b>{{ $t("Services Cost") }}</b>-->
<!--                  </div>-->

<!--                  <div class="col-6 text-right">-->
<!--                    €{{ appointment.cost - appointment.travelFee }}-->
<!--                    <span v-if="appointment.services.findIndex(a => a.priceType === 'FROM') !== -1">+</span>-->
<!--                  </div>-->

<!--              </div>-->
              <div class="row pb-2" v-if="appointment.type === 'MOBILE'">

                <div class="col-6">
                  <b>{{ $t("Travel Cost") }}</b>
                </div>

                <div class="col-6 text-right">€{{ appointment.travelFee }} </div>

              </div>
              <div class="row total">
                <div class="col-6">
                  <b>{{ $t("Total") }}</b>
                </div>

                <div class="col-6 text-right">

                  <template v-if="appointment.services.findIndex(a => a.priceType === 'FROM') !== -1">
                    <span class="opacity-6 text-md">{{ $t("From")}} </span> <br>
                  </template>
                  <b>€<span :style="appointment.redeemLoyaltyPoints ? 'text-decoration: line-through' : ''">{{appointment.cost}} </span> {{appointment.redeemLoyaltyPoints ? "0" : ""}}</b> <br>
                  <b>{{convertMinsToHrsMins(appointment.duration)}}</b> <br>
                </div>
              </div>
            </div>
          </div>
<!--            </card>-->
          <div :id="'target-'+appointment.id" class="collapse multi-collapse pt-3" >
            <p v-if="appointment.redeemLoyaltyPoints">
              The cost of this appointment is 0 because you redeemed your loyalty points
            </p>
            Extra info for the appointment
          </div>
        </card>
          </div>
        </div>
      </div>
    <modal :show="showRescheduleModal" @close="closeModal">
      <template v-slot:header>
        <h2 class="modal-title" id="exampleModalLabel">
          {{ $t("Reschedule Appointment") }}
        </h2>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12 mb-3">
            {{$t("Please select date and time for your appointment")}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" style="min-width: 290px;margin: 0 auto;">
            <Datepicker
                :locale="$store.state.locale"
                :disabled="gettingSlots"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                :minDate="new Date()"
                :maxDate="lastAvailableDate"
                :autoApply="true"
                :clearable="false"
                :noToday="true"
                nowButtonLabel="Today"
                inline
                v-model="editAppointment.startDate" ></Datepicker>
          </div>
          <div class="col-md-6 pt-2 availableSlots">

            <template v-if="!gettingSlots">
              <template v-if="availableSlots.list.length > 0">
                <div v-bind:key="time" v-for="time in availableSlots.list" class="float-left" style="width: 33%;text-align: center;">
                  <button @click="selectTime(time)" :class="editAppointment.startTime !== time ? 'btn btn-secondary m-2' : 'btn btn-primary m-2'">{{time}}</button>
                </div>
              </template>
              <template v-else>
                <template v-if="availableSlots.isClosed" >
                  <div class="margin-center text-center">
                    <i style="font-size: xx-large;text-align: center;width: 100%;" class="far fa-frown"></i>
                    <label>
                   {{
                        $t("The company is not working on the selected day. Please select another day")
                      }}</label>
                  </div>
                </template>
                <template v-else>
                  <div class="margin-center text-center">
                    <i style="font-size: xx-large;text-align: center;width: 100%;" class="far fa-frown"></i>
                    <label>Fully Booked</label>
                  </div>
                </template>
              </template>
            </template>
            <div v-else class="col-12 align-items-center margin-center" style="font-size: 30px;">
              <span
                  class="fas fa-cog fa-spin"
                  role="status"
                  aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            id="reschedule-submit-btn"
            :alertMessage="errorMessage"
            :state="loadingProcess"
            @clicked="submitReschedule()"
            type="primary"
        >{{$t("Submit")}}</submit-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {store} from "@/store";
import {convertMinsToHrsMins, getStatusClass, getStatusIconClass} from "@/directives/helper";
import moment from "moment";
// import {useToast} from "vue-toastification";
import FirebaseFunctionsHelper from "@/plugins/firebaseFunctionsHelper";
import ServicePrice from "@/views/Components/ServicePrice";
import BaseButton from "@/components/BaseButton";
import SubmitButton from "@/components/SubmitButton";
import Datepicker from '@vuepic/vue-datepicker';
import {logEvent} from "firebase/analytics";
import {analytics} from "@/main";

export default {
  name: "UserAppointment",
  components: {SubmitButton, BaseButton, ServicePrice, Datepicker},
  data() {
    return {
      loadingProcess: "notReady",
      errorMessage: "",
      availableSlots: {
        list: [],
        isClosed: false},
      gettingSlots: false,
      showRescheduleModal: false,
      now: moment(),
      appointmentLocationSearch: "",
      loading: true,
      lastAvailableDate: null,
      appointment: {
        mStartTime: null,
        startTime: null,
        address: {
          street: null
        }
      },
      editAppointment: {
        startDate: null,
        startTime: null,
        mStartDate: null
      }
    }
  },
  computed: {
    isPastAppointment() {
      if(this.appointment) {
        return this.appointment.mStartTime < moment()
      }
      return false
    }
  },
  async mounted() {
    await this.getUserAppointment()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line no-prototype-builtins
    if(this.appointment.address.hasOwnProperty("coords")){
      this.appointmentLocationSearch = this.appointment.address.coords.latitude + "," + this.appointment.address.coords.longitude
    }
  },
  watch: {
    "editAppointment.startDate": function () {
      if (this.editAppointment.startDate) {
        this.dateSelected()
      }
    }
  },
  methods: {
    hasHistory () { return window.history.length > 2 },
    dateSelected() {
      this.editAppointment.mStartDate = moment(this.editAppointment.startDate, "yyyy-MM-DD HH:mm:ss Z");
      this.availableSlots = { list: [], isClosed: false};
      this.gettingSlots = true;
      this.getAvailableHours()
    },
    getStatusClass,
    getStatusIconClass,
    convertMinsToHrsMins,
    notifyCancelAppointment(appointment){
      let self = this;

      this.$swal({
        title: self.$t('Are you sure?'),
        text: self.$t('You are about to cancel this appointment!'),
        showConfirmButton: true,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'teal',
        confirmButtonText: self.$t('Yes'),
        cancelButtonText: self.$t('No'),
        closeOnConfirm: false,
        cancelButtonColor: '#d33',
        html: '<div class="text-left">' +
            '<div class="mb-1"><b>'+ self.$t("Company") +'</b>: '+appointment.companyName+ '</div>' +
            '<div class="mb-1"><b>'+ self.$t("Start time") +':</b> '+appointment.mStartTime.format("DD MMM YYYY [at] HH:mm a")+'</div>'+
            '</div>',
      }).then(function (value) {
        if(value.isConfirmed) {

          self.$swal.fire({
            title: self.$t('Please wait'),
            showConfirmButton: false,
            timerProgressBar: true,
            didOpen: async () => {
              self.$swal.showLoading()
              let result = await self.cancelAppointment()
              if(result.result) {

                logEvent(analytics, 'appointment_cancel', {
                  companyId: self.appointment.companyId,
                  type: self.appointment.type,
                });

                self.$swal({
                  title: self.$t('Appointment cancelled!'),
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                })
              } else {
                self.$swal({
                  title: self.$t('There was a problem!'),
                  text: self.$t(result.message ?? ""),
                  icon: 'error',
                  showConfirmButton: true,
                  confirmButtonColor: 'teal',
                  confirmButtonText: self.$t('Ok')
                })
              }
            }
          })
        }
      });

    },
    closeModal(){
      this.showRescheduleModal = false
      this.editAppointment.startTime = null
      this.editAppointment.startDate = null
      this.editAppointment.mStartDate = null
    },
    async cancelAppointment() {

      if (this.appointment.mStartTime > moment()) {
        let data = {}
        data.companyId = this.appointment.companyId
        data.id = this.appointment.id
        // const toast = useToast()
        // this.$swal.close()
        // this.$swal({
        //   title: this.$t('Appointment rescheduled!'),
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 1000
        // })
        return FirebaseFunctionsHelper.callFunction(
            "cancelAppointment",
            // eslint-disable-next-line no-undef
            data
        ).then((result) => {

          // eslint-disable-next-line no-prototype-builtins
          if (result && result.hasOwnProperty("message")) {
            return {result: false, message: result.details}
            // toast.error("There was a problem! Try again later.");
          }
          this.getUserAppointment()
          return {result: true}
          // toast.success("Successfully Cancelled!", { timeout: 4000 });

        }).catch(error => {
          console.error(error)
          return {result: false, message: error.message}
          // toast.error("There was a problem!" + error.message);
        });

      } else {
        alert("not good")
      }
    },
    openRescheduleModal(){
      this.editAppointment.startDate = this.appointment.startTime
      this.editAppointment.mStartDate = moment(this.appointment.startTime);
      this.getCompanyBookingSettings()
      this.showRescheduleModal = true
    },
    async getAvailableHours() {
      let data = {
        companyId: this.appointment.companyId,
        duration: this.appointment.duration,
        employeeId: this.appointment.employeeId,
        startDate: this.editAppointment.mStartDate.format("yyyy-MM-DD HH:mm:ss Z"),
        serviceLocation: this.appointment.type,
        appointmentId: this.appointment.id
      };
      FirebaseFunctionsHelper.callFunction(
          "getAppointmentAvailableTimes",
          // eslint-disable-next-line no-undef
          data
      ).then((result) => {
        this.gettingSlots = false;
        // eslint-disable-next-line no-prototype-builtins
        if (result && result.hasOwnProperty("message")) {
          this.showError = true;
          this.alertMessage = result.message;
          return;
        }

        const times = [];
        result.list.forEach( time => {
          times.push(moment(time, "yyyy-MM-DD HH:mm:ss Z").format("HH:mm"))
        })
        this.availableSlots.isClosed = result.isClosed;
        this.availableSlots.list = times

      });

    },
    selectTime(time) {
      this.editAppointment.startTime = time
      let date = this.editAppointment.startDate.toISOString().split('T').shift()
      this.editAppointment.mStartDate = moment(date + " " + time)
      // document.getElementById("reschedule-submit-btn").scrollIntoView();

      this.loadingProcess = ""

      const element = document.getElementById("reschedule-submit-btn")
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    submitReschedule(){
      let self = this;
      let data = {
        companyId: this.appointment.companyId,
        id: this.appointment.id,
        startTime: this.editAppointment.mStartDate.format("yyyy-MM-DD HH:mm:ss Z")
      }
      this.loadingProcess = 'processing';
      FirebaseFunctionsHelper.callFunction(
          "rescheduleAppointment",
          // eslint-disable-next-line no-undef
          data
      ).then((result) => {
        // eslint-disable-next-line no-prototype-builtins
        if (result && result.hasOwnProperty("message")) {
          this.alertMessage = result.message;
          this.loadingProcess = "failed";
          // self.$swal({
          //   title: self.$t('There was a problem!'),
          //   text: self.$t(result.message),
          //   icon: 'error',
          //   showConfirmButton: false,
          //   timer: 3000
          // })
          return;
        }

        logEvent(analytics, 'appointment_reschedule', {
          companyId: self.appointment.companyId,
          type: self.appointment.type,
        });

        this.loadingProcess = "done";
        this.getUserAppointment()
        this.showRescheduleModal = false
            this.$swal({
              title: this.$t('Appointment rescheduled!'),
              icon: 'success',
              showConfirmButton: false,
              timer: 1000
            })
      })
    },
     getCompanyBookingSettings() {
      let self = this;
      this.$store
          .dispatch("fetchBookingSettings", { id: this.appointment.companyId })
          .then((data) => {
            if (data) {
              // self.bookingSettings = data;
              self.lastAvailableDate = moment().add(data.futureBooking, 'minutes').toDate()
              // self.loadingCompany = false
            }
          });
    },
    async getUserAppointment() {
      this.loading = true
      let self = this
      await store
          .dispatch("getUserAppointment", this.$route.params.id)
          .then((data) => {
            if(data){
              self.appointment = data
              self.loading = false
            } else {
              self.$router.push({
                name: 'error',
                params: { desc: 'The appointment you are trying to see does not exist.' }
              })
            }
          })
        .catch(error => {
          console.error(error)
          self.$router.push({
            name: 'error',
            params: { desc: "There was a problem fetching your appointment." }
          })
        })
    }
  }
}
</script>

<style>
@media(min-width: 767px) {
  div.new-line-on-mobile {
    display: inline-block;
  }
}

/*for mobile */
@media(max-width: 800px) {
  div.mobile-left-align {
    text-align: left !important;
  }
}
@media(max-width: 500px) {
  h1.display-2.text-white {
    font-size: 30px;
  }
}

@media(min-width: 680px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 650px;
  }
}

.availableSlots{
  margin: 0 auto;
  text-align: center;
}



</style>