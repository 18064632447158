<template>
  <div>
  <base-button
    @click="handleClicked"
      :class="
                      state === 'failed'
                        ? 'bg-gradient-red border-0'
                        : 'bg-primary border-0'
                    "
      :disabled="state !== '' && state !== 'failed'"
  >
    <template v-if="state === 'processing'">
                      <span
                          class="fas fa-cog fa-spin"
                          role="status"
                          aria-hidden="true"
                      ></span>
      Loading...
    </template>
    <template v-else-if="state === 'done'">
                      <span
                          class="fas fa-money-check"
                          role="status"
                          aria-hidden="true"
                      ></span>
      Done!
    </template>

    <template v-else> <slot></slot> </template>
  </base-button>

  <base-alert
      v-if="state === 'failed'"
      type="danger"
      dismissible
  >

    <!--                    <span class="alert-inner&#45;&#45;icon"><i class="ni ni-hat-3"></i></span>-->
    <span class="alert-inner--text">
      <strong>Process Failed!</strong><br>
      <label class="pl-1">{{ $t(alertMessage)}} </label>
    </span
    >
    <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </base-alert>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    action: { type: Function },
    alertMessage: {
      type: String,
      default: "There was an error.",
      description: "Message to show in alert if process fail",
    },
    state: {
      type: String,
      default: "",
      description: "State of submission",
    },
  },
  methods: {
    handleClicked(evt) {
      this.$emit("clicked", evt);
    },
    created() {
      this.$emit("action");
    }
  },
};
</script>

<style scoped>

</style>