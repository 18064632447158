<template>
  <nav
      class="navbar sticky-top"
      :class="[
      { 'navbar-expand-md': expand },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
    ]"
  >
    <div :class="containerClasses">
      <slot v-if="!['Company', 'QuickBook'].includes($route.name)" name="brand">
        <a href="/">
        <img  alt="apoointme logoo" width="60" src="../assets/logo.png" class=""/>
        </a>
        <label class="h2 mb-0 text-white navbar sticky-top navbar-expand-md navbar-top navbar-horizontal bg-gradient-primary d-none d-lg-inline-block" v-if="$route.name === 'home'">AppointMe</label>
<!--        <label class="h1 mb-0 text-white  d-none d-lg-inline-block pl-2" v-if="$route.name === 'QuickBook'">Book your Appointment</label>-->
        <router-link
            v-if="$route.name !== 'home'"
            :to="$route.path"
            class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
        >
          <div v-if="$store.state.company">
            {{ $store.state.company.companyName }}
          </div>
          <div v-else>
            {{ $route.name }}
          </div>
        </router-link>
      </slot>

      <slot v-else name="brand">
        <label v-if="$store.state.companyActive !== null" class="h1 mb-0 text-white opacity-8 d-lg-inline-block hide-on-mobile">{{$store.state.companyActive}}</label>
        <img v-else alt="apoointme logo" width="60" src="../assets/logo.png" class="hide-on-mobile"/>
      </slot>
<!-- Mobile lang-->

      <navbar-toggle-button
          class="mb-only"
          v-if="showToggleButton"
          :toggled="langToggled"
          :target="contentId+1"
          style="width: 95px"
          @click.stop="langToggled = !langToggled"
      >
        <a
            class="no-caret"
            style="cursor: pointer"
            data-toggle="dropdown"
        >
          <span
              :class="[
                  { 'text-white': !$store.state.companyColorIsLight},
                  {'text-dark': $store.state.companyColorIsLight}
              ]"
              class="mb-0 m-2 font-weight-bold d-block float-left">{{ $store.state.locale.toUpperCase() }} <i class="fa fa-globe"></i></span>
        </a>
      </navbar-toggle-button>
      <div
          class="collapse navbar-collapse mb-only"
          style="overflow: hidden;"
          :class="{ show: langToggled }"
          :id="contentId"
          v-click-outside="closeLangMenu"
      >
        <base-button type="link" @click="setLocale(locale.value)" class="nav-link dropdown-item" v-bind:key="locale.value" v-for="locale in locales">
          {{locale.value}}
        </base-button>
      </div>
<!-- End Mobile lang-->

<!-- Mobile menu -->
      <navbar-toggle-button
          v-if="showToggleButton"
          :toggled="toggled"
          :target="contentId"
          @click.stop="toggled = !toggled"
          style="width: 95px"
      >
        <span
            :class="[
                  { 'text-white': !$store.state.companyColorIsLight},
                  {'text-dark': $store.state.companyColorIsLight}
              ]"
            class="fa fa-bars"></span>
      </navbar-toggle-button>

      <div
          class="collapse navbar-collapse"
          :class="{ show: toggled }"
          :id="contentId"
          @click="toggleMenu"
          v-click-outside="closeMenu"
      >
        <slot :close-menu="closeMenu"></slot>
      </div>

<!-- End Mobile menu -->
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";
import BaseButton from "@/components/BaseButton";
// import moment from "moment";
import 'moment/locale/el'
// import BaseDropdown from "@/components/BaseDropdown";
// import BaseButton from "@/components/BaseButton";

export default {
  name: "base-nav",
  components: {
    BaseButton,
    // BaseButton,
    // BaseDropdown,
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
          "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      langToggled: false,
      toggled: false,
      locales: [
        {label: 'English', value: 'EN'},
        {label: 'Ελληνικά', value: 'EL'},
        // {label: 'Russian', value: 'ru'}
      ],
    };
  },
  mounted() {
    // console.log(this.$route)
  },
  methods: {
    setLocale (locale) {
      // this.$store.state.locale = value
      // console.log(locale.toLowerCase())
      this.$i18n.locale = locale.toLowerCase()
      this.$store.dispatch('setLocale', locale.toLowerCase())
       // without this line it didn't work
      this.$moment.locale(locale.toLowerCase())
      this.closeLangMenu();
    },
    toggleMenu() {
      this.toggled = !this.toggled
    },
    closeMenu() {
      this.toggled = false;
    },
    closeLangMenu() {
      this.langToggled = false;
    },
  },
};
</script>
<style>
@media(min-width: 767px)  {
  .navbar-expand-md .navbar-collapse.mb-only{
    display: none !important;
  }
}
</style>
