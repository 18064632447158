<template>
  <div  :style="isSafari ? '0' : 'padding-top: 78px'" style="height: 100%">
    <div class="row col-12 col-md-10 pt-4 mobile-no-padding" style="margin: 0 auto;z-index: 100;">
      <div v-if="step !== 'companyInfo'" class="stepper-wrapper col-md-6 col-12">
        <div class="d-inline-block pt-4 ">
          <h1 class="float-left">{{ $t(step) }}</h1>
        </div>
          <div v-if="!['authStep', 'thankYou'].includes(step)" class="progress mb-2">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            aria-valuenow="70"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="'width:' + progress + '%'"
          ></div>
        </div>
        <div class="row align-items-center pt-2 pb-2 pl-3">
          <button
            v-if="!['thankYou'].includes(step)"
            type="button"
            class="btn btn-link"
            style="padding-right:0;padding-left:0;"
            @click="previous()"
          >
            <i class="fa fa-chevron-left" ></i>
              {{ $t("Back") }}

          </button>

          <div class="col align-self-end stepperControlls pr-3 text-right">
            <!-- Stepper Controls -->
            <button
                v-if="
              (['services'].includes(step) && services.length >= 5 && appointment.services.length > 0)
            "
                type="button"
                class="btn btn-primary ml-2"
                @click="next()"
            >
              {{ $t("Next") }}
            </button>
            </div>
        </div>
        <template v-if="step === 'error'">
          <label>{{ errorMessage }}</label>
        </template>
<!--        <template v-else-if="step === 'companyInfo'">-->
<!--&lt;!&ndash;          <company-info></company-info>&ndash;&gt;-->
<!--        </template>-->
        <template v-else-if="step === 'loadingStep'">
          <div class="col align-self-center text-center">
          <span class="fas fa-cog fa-spin text-lg" role="status" aria-hidden="true"></span>
          </div>
        </template>
        <template v-else-if="step === 'authStep'">
          <form role="form">
            <label>{{ $t("Provide your phone number") }}</label>
            <base-input
                type="text" pattern="\d*"
              formClasses="input-group-alternative mb-3 phone-input"
              style="display: flex;border-radius: 0;"
              :placeholder="$t('Phone Number')"
              :disabled="codeSent"
              v-model="phoneNumber"
            >
              <template v-slot:addonLeft>
                <i class="fa fa-phone pr-2"></i> +357
              </template>
              <template v-if="codeSent" v-slot:addonRight>
                <base-button size="sm" class="p-0" type="link" @click="resetAuthForm" >Edit </base-button>
              </template>
            </base-input>
<label class="ml-1 text-red text-sm" v-if="v$.phoneNumber.$invalid && v$.phoneNumber.$errors.length > 0">{{v$.phoneNumber.$errors.map( err => { return err.$message }).join()}}</label>

            <base-input
                type="text" pattern="\d*"
              formClasses="input-group-alternative mb-3"
              v-if="codeSent"
              :placeholder="$t('Verification Code')"
              addon-left-icon="fa fa-key"
              v-model="code"
            >
            </base-input>
            <base-alert v-if="showAlert" :type="alertType" dismissible>
              <strong></strong> {{$t(alertMessage)}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
            <!--            <base-checkbox class="custom-control-alternative">-->
            <!--              <span class="text-muted">Remember me</span>-->
            <!--            </base-checkbox>-->
            <div class="text-center">
              <base-button v-if="!codeSent" @click="getCode" :disabled="sendingCodeStatus" type="primary" >
                <template v-if="sendingCodeStatus">
                  <span class="fas fa-cog fa-spin" role="status" aria-hidden="true"></span>
                </template>
                <template v-else> {{ $t("Get verification code") }} </template>
              </base-button>
              <base-button v-else type="primary" @click="signIn" :disabled="code.length !== 6 || signInLoader" class="mt-4 mb-2">
                <template v-if="signInLoader">
                  <span class="fas fa-cog fa-spin" role="status" aria-hidden="true"></span>
                </template>
                <template v-else>
                  {{ $t("Submit Code") }}
                </template>
              </base-button>
            </div>
            <div id="recaptcha-container"></div>
          </form>
<!--          <div v-if="$store.state.user.claims.user_id" class="col-6 margin-center p-3">-->
<!--&lt;!&ndash;            <div>&ndash;&gt;-->

<!--&lt;!&ndash;              You are logged in as <b></b>. <br>&ndash;&gt;-->
<!--&lt;!&ndash;              Is it you?&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="text-right p-2">-->
<!--              <base-button type="primary" size="md" @click="next" > Yes</base-button>-->
<!--              <base-button type="danger" size="md" @click="logout">No</base-button>-->
<!--            </div>-->
<!--          </div>-->
        </template>
        <template v-else-if="step === 'registerStep'">
          <div class="row">
            <div class="col-lg-6">
              <base-input
                  alternative=""
                  :label="$t('First name')"
                  :placeholder="$t('First name')"
                  input-classes="form-control-alternative"
                  v-model="userDetails.firstName"
                  :valid="!v$.userDetails.firstName.$invalid"
                  :error="v$.userDetails.firstName.$errors.map( err => { return err.$message }).join()"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                  alternative=""
                  :label="$t('Last name')"
                  :placeholder="$t('Last name')"
                  input-classes="form-control-alternative"
                  v-model="userDetails.lastName"
                  :valid="!v$.userDetails.lastName.$invalid"
                  :error="v$.userDetails.lastName.$errors.map( err => { return err.$message }).join()"
              />
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">{{ $t('Gender') }}</label>
                <select class="form-control  form-control-alternative"  name="gender" id="gender" v-model="userDetails.gender">
                  <option :key="option.value" v-for="option in genderOptions" :value="option.value"> {{option.text}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <base-input
                  class="pt-0"
                  alternative=""
                  :label="$t('Contact Email')"
                  :placeholder="$t('Contact Email')"
                  input-classes="form-control-alternative pd-0"
                  v-model="userDetails.email"
                  :valid="!v$.userDetails.email.$invalid"
                  :error="v$.userDetails.email.$errors.map( err => { return err.$message }).join()"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
            <div class="text-sm pb-3">
              {{ $t("Email is needed in order to keep you updated about any appointment booked.") }}</div>

            </div>
          </div>
          <div class="row ">
            <div class="col col-lg-12 align-self-end text-right">
              {{$t("Please read & accept the")}} <a href="https://appointme.net/privacy-policy.html" target="_blank">{{$t("Terms and Conditions")}}</a>
              <input
class="ml-3"
:style="v$.userDetails.isPolicyChecked.$invalid === true ? 'outline: 1px solid red;outline-style: dotted;' :''"
                  :class="[
          { 'is-valid': !v$.userDetails.isPolicyChecked.$invalid === true },
          { 'is-invalid': !v$.userDetails.isPolicyChecked.$invalid === false },
        ]"
                  type="checkbox"

                  v-model="userDetails.isPolicyChecked"
              />

              <div class=" text-danger invalid-feedback" style="display: inline-block" v-if="v$.userDetails.isPolicyChecked.$invalid">
                {{v$.userDetails.isPolicyChecked.$errors.map( err => { return err.$message }).join()}}
              </div>
            </div>
<!--            <div class="col-12">-->
<!--              <label class="text-black-50">Contact email will be used to inform you about any updates of your upcoming appointments.</label>-->
<!--            </div>-->
          </div>
        </template>
        <template v-else-if="step === 'selectType'">
          <div class="col-12 btn option-button bg-white" @click="selectType('BUSINESS')">
            <div class="title">{{ $t("Business Appointment")}}</div>
            <div class="sub-title text-md text-black-50">{{ $t("At business Location")}}</div>
          </div>
          <div class="col-12 btn option-button bg-white" @click="selectType('MOBILE')">
            <div class="title">{{ $t("Remote Appointment")}}</div>
            <div class="sub-title text-md text-black-50">{{ $t("At my Location")}}</div>
          </div>
        </template>
        <template v-else-if="step === 'selectAddress'">
          <div class="row">
            <div class="col-md-6">
              <base-input
                  alternative=""
                  :label="$t('Street')"
                  :placeholder="$t('Street')"
                  input-classes="form-control-alternative"
                  v-model="appointment.address.street"
                  :valid="!v$.appointment.address.street.$invalid"
                  :error="v$.appointment.address.street.$errors.map( err => { return err.$message }).join()"
              />
            </div>
            <div class="col-md-6">
              <base-input
                  alternative=""
                  :label="$t('Street')"
                  :placeholder="$t('Street 2')"
                  input-classes="form-control-alternative"
                  v-model="appointment.address.street2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">

                <div class="form-group has-label" :class="{'has-danger': v$.appointment.address.city.$invalid }">
                  <label class="form-control-label">{{$t('City')}}</label>
                  <select class="form-control form-control-alternative" :name="$t('City')" id="city" v-model="appointment.address.city">
                    <option value=""> {{ $t("Select City") }} </option>
                    <option :key="city" v-for="city in cities" :value="city"> {{$t(city)}}</option>
                  </select>

                    <div v-if="v$.appointment.address.city.$invalid" class="text-danger invalid-feedback" style="display: block;">
                      {{v$.appointment.address.city.$errors.map( err => { return err.$message })[0]}}
                    </div>

                </div>

<!--              <base-input-->
<!--                  alternative=""-->
<!--                  label="City"-->
<!--                  placeholder="City"-->
<!--                  input-classes="form-control-alternative"-->
<!--                  v-model="appointment.address.city"-->
<!--                  :valid="!v$.appointment.address.city.$invalid"-->
<!--                  :error="v$.appointment.address.city.$errors.map( err => { return err.$message }).join()"-->

<!--              />-->
            </div>
            <div class="col-lg-4">
              <base-input
                  disabled=""
                  alternative=""
                  :label="$t('Country')"
                  :placeholder="$t('Country')"
                  input-classes="form-control-alternative"
                  v-model="appointment.address.country"
                  :valid="!v$.appointment.address.country.$invalid"
                  :error="v$.appointment.address.country.$errors.map( err => { return err.$message }).join()"
              />
            </div>
            <div class="col-lg-4">
              <base-input
                  alternative=""
                  :label="$t('Postal code')"
                  :placeholder="$t('Postal code')"
                  input-classes="form-control-alternative"
                  v-model="appointment.address.postalCode"
                  :valid="!v$.appointment.address.postalCode.$invalid"
                  :error="v$.appointment.address.postalCode.$errors.map( err => { return err.$message }).join()"
              />
            </div>
            <div class="col-lg-6">
            <base-switch
                :label="$t('Save address for next time?')"
                v-model="appointment.saveClientAddress"
                :value="appointment.saveClientAddress"
            ></base-switch>
              </div>
          </div>
        </template>
        <template v-else-if="step === 'services'">
          <div v-if="services.length === 0 && loadingServices" class="col align-self-center text-center">
            <span class="fas fa-cog fa-spin text-lg" role="status" aria-hidden="true"></span>
          </div>
          <!-- Step 2 Content -->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <h3>Select Services ({{ appointment.services.length }})</h3>-->
<!--            </div>-->
<!--          </div>-->
          <div v-else class="">
            <div v-bind:key="name" v-for="(value, name) in servicesGrouped">
            <h2>{{value[0].categoryName ?? "Other"}}</h2>
              <div
                  v-bind:key="service.id"
                  v-for="service in value"
                  class="col-12 btn option-button bg-white"
                  @click="selectService(service)"
              >
                <div class="row">
                  <div class="col-2 text-center service-btn-check">
                    <i
                        style="
                      font-size: 30px;
                      position: relative;
                      top: calc(50% - 15px);
                    "
                        :class="
                      !appointment.services.includes(service)
                        ? 'far fa-circle'
                        : 'fas fa-check-circle'
                    "
                    >
                    </i>
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class=" col-12 col-md-9">
                        <span class="service-title d-block">{{ service.name }}</span>
                        <div class="d-block description service-description text-black-50">
                          <span class="">{{convertMinsToHrsMins(service.duration)}}</span>
                          <i class="fa fa-circle" style="font-size: 5px;"></i>
                          <span>{{ $t(service.gender)}}</span>
                        </div>
                      </div>
                      <div class="sub-title col-12 col-md-3 text-right">
                          <service-price :cost="service.cost" :price-type="service.priceType" :special-price="service.specialPrice"></service-price>
                      </div>
                    </div>

                    <div class="service-description description pt-2">
                      <span v-html="service.description"></span><br>
                      <div class="pt-2 text-xs text-muted" v-if="service.hasOwnProperty('excludedDays') && service.excludedDays.length > 0">
                        <b>{{$t("Not available for:")}}</b> {{ service.excludedDays.map( day => $t(day.toLocaleString())).join()}}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <br><br>
            </div>

          </div>
        </template>
        <template v-else-if="step === 'selectEmployee'">
          <span
            v-if="fetchingEmployees"
            style="width: 100%;"
            class="fas fa-cog fa-spin text-center"
            role="status"
            aria-hidden="true"
          ></span>
          <div v-else v-bind:key="employee.id" v-for="employee in employees" class="col-12 btn option-button bg-white" @click="selectEmployee(employee)">
            <div class="row align-items-center">
              <div class="col-4" style="max-width:150px; text-align: center;">
                <div class="image-cropper">
                  <img v-if="employee.imageUrl" :src="employee.imageUrl" class="rounded" />
                  <i v-else class="fa fa-user-circle" style="font-size: 55px;"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="title">
                  {{ employee.firstName }} {{ employee.lastName }}
                </div>
                <div class="sub-title">
                  {{ employee.title }}
                </div>
              </div>
              <div class="col-2 text-right">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="step === 'selectDate'">
          <!-- Step 3 Content -->
          <div class="row">
          <div class="col-md-12">
            <scroll-date-picker :selectedDate="appointment.startDate" :disabledDates="disabledDates" @update:selectedDate="appointment.startDate = $event"  :minDate="new Date()" :maxDate="lastAvailableDate"></scroll-date-picker>
<!--            <Datepicker-->
<!--                :locale="$store.state.locale"-->
<!--                :disabled="gettingSlots"-->
<!--                :monthChangeOnScroll="false"-->
<!--                :enableTimePicker="false"-->
<!--                :minDate="new Date()"-->
<!--                :maxDate="lastAvailableDate"-->
<!--                :disabled-dates="disabledDates"-->
<!--                :autoApply="true"-->
<!--                :clearable="false"-->
<!--                :noToday="true"-->
<!--                nowButtonLabel="Today"-->
<!--                inline-->
<!--                v-model="appointment.startDate" ></Datepicker>-->
          </div>
          <div class="col-md-12 pt-4 availableSlots">

              <template v-if="!gettingSlots">
                <template v-if="availableSlots.list.length > 0">
                  <ul class="w-100 p-0">
                  <li style="list-style-type: none;" v-bind:key="time" v-for="time in availableSlots.list" >
                    <button class="w-100 p-3 border-0 text-dark" @click="selectTime(time)" :class="appointment.startTime !== time ? 'p-3 bg-white' : 'p-3'">
                     <span class="float-left">{{ time}}</span>
                      <i class="fa fa-chevron-right float-right"></i>
                    </button>
                    <hr class="m-0" />
                  </li>
                  </ul>
                </template>
                <template v-else>
                  <template v-if="availableSlots.isClosed" >
                    <div class="margin-center text-center">
                      <i style="font-size: xx-large;text-align: center;width: 100%;" class="far fa-frown"></i>
                      <label>
                        {{
                          $t("The company is not working on the selected day. Please select another day")
                        }}</label>
                    </div>
                  </template>
                  <template v-else>
                      <div class="margin-center text-center">
                      <i style="font-size: xx-large;text-align: center;width: 100%;" class="far fa-frown"></i>
                      <label>Fully Booked</label>
                      </div>
                  </template>
                </template>
            </template>
            <div v-else class="" style="font-size: 30px;">
              <span
                class="fas fa-cog fa-spin"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
          </div>
        </template>
        <template v-else-if="step === 'noteConfirm'">
          <base-input alternative="">
              <textarea
                rows="5"
                class="form-control form-control-alternative"
                :placeholder="$t('Booking Notes')"
                v-model="appointment.notes"
              >
              </textarea>
          </base-input>
          <div class="float-right text-right">
<!--            <template v-if="canRedeem">-->
<!--              <span class="pb-1">{{$t("loyaltyEligibleMessage")}}</span>-->
<!--              <submit-button class="stepperControlls" @clicked="redeem()" style="float: right;padding-left: 10px;" :alertMessage="errorMessage" :state="loadingRedeemProcess"  size="md">-->
<!--                {{ $t("Redeem Free") }}</submit-button>-->
<!--            </template>-->
            <submit-button class="stepperControlls" :state="loadingProcess" @clicked="redeemOrNot()" style="float: right;" size="md" type="primary">{{ $t("Submit") }}</submit-button>
          </div>
        </template>
        <template v-else-if="step === 'thankYou'">
          <div class="mt-3">
            <div style="    font-size: 21px;
    font-weight: bold;color: #198754!important;">{{$t("Thank you for choosing us!")}}</div>

            <div>{{ $t("Your appointment was successfully submitted!") }} </div>
<!--            <a v-if="appointmentLink !== ''" class="btn btn-primary mt-3" :href="appointmentLink">-->
<!--              {{$t("Go to your appointment")}}-->
<!--            </a>-->
            <router-link
                v-if="appointmentLink !== ''"
                :to="appointmentLink"
                class="btn btn-primary mt-3"
            >
              {{$t("Go to your appointment")}}
            </router-link>

            <p class="pt-2" v-if="(appointment.type === 'BUSINESS' && !bookingSettings.autoApprove) || (appointment.type === 'MOBILE' && !bookingSettings.mobileAutoApprove)">
              {{ $t("You will receive a confirmation email as soon as your appointment is confirmed by") }} {{company.companyName}}.</p>
            <p class="pt-2" v-if="(appointment.type === 'BUSINESS' && bookingSettings.autoApprove) || (appointment.type === 'MOBILE' && bookingSettings.mobileAutoApprove)">
              {{ $t("You appointment is confirmed, and you will receive the confirmation email shortly.") }}</p>
<!--            <base-button @click="startAgain()" type="link" >{{$t("Book again")}}</base-button>-->
          </div>
        </template>
        <div class="stepperControlls">
          <!-- Stepper Controls -->
          <button
              v-if="
              (['services'].includes(step) && appointment.services.length > 0) ||
              ['selectAddress'].includes(step)
            "
              type="button"
              class="btn btn-primary ml-2"
              @click="['selectAddress'].includes(step) && this.v$.appointment.address.$invalid ? this.v$.appointment.$touch() : next()"
          >
            {{ $t("Next") }}
          </button>
          <submit-button v-if="step === 'registerStep'" :alertMessage="alertMessage" @clicked="registerStepSubmit()" :state="registerLoadingProcess" size="md" class="ml-2">
            {{ $t("Next") }}</submit-button>
<!--          <submit-button v-if="step === 'noteConfirm'" @clicked="submit()" :state="loadingProcess"  size="md" type="primary">Submit</submit-button>-->
        </div>
      </div>
      <div class="col-12 col-md-4 summary-boxxxvg6" :class="{'sumbit-appt': step !== 'noteConfirm', 'col-md-6 mobile-no-padding': step === 'companyInfo'}" :style="['noteConfirm', 'companyInfo'].includes(step) ? 'display:block;' : ''">
        <photo-slider
            v-if="step === 'companyInfo' && !loadingCompany && company.hasOwnProperty('gallery') && company.gallery.length > 0"
            class="only-on-mobile"
            :photos="company.hasOwnProperty('gallery') ? company.gallery.map(a => a.url) : []"
            height="300px"
        >

        </photo-slider>

        <div v-if="loadingCompany" style="position: fixed; width: 100%;left: 0;" class="col pt-3 align-self-center text-center">
          <span class="fas fa-cog fa-spin text-lg" role="status" aria-hidden="true"></span>
        </div>
        <template v-else>
        <div class="apment-summary mobile-no-margin" :class="{'white-bg': step === 'companyInfo'}"  >
          <br>
          <h1>{{company ? company.companyName : "" }}</h1>
          <extra-features :bookingSettings="bookingSettings"></extra-features>
          <extra-info v-if="step === 'companyInfo'" :company="company"></extra-info>

          <p class="pt-3 no-select" v-if="step === 'companyInfo'">{{company.description}}</p>
          <p v-if="step === 'companyInfo'" class="pt-2 pb-2">

            <working-hours  :workingHours="company.hasOwnProperty('openingHours') ? company.openingHours : {}"></working-hours>
          </p>
          <div class="pb-3">
            <h3 v-if="step === 'companyInfo'">{{$t("Contact Details")}}</h3>
            <table style="width: 100%;">
              <tbody>
                  <tr v-if="company.hasOwnProperty('contactPhone') && company.contactPhone !== null && step === 'companyInfo'">
                    <th class="pr-2"><i class="fa fa-phone"></i></th>
                    <td> <a :href="'tel:'+company.contactPhone">{{company.contactPhone}}</a></td>
                  </tr>
                  <tr v-if="company.hasOwnProperty('contactEmail') && company.contactEmail !== null && step === 'companyInfo'">
                    <th class="pr-2"><i class="fa fa-envelope"></i></th><td> {{company.contactEmail}}</td>
                  </tr>
              <tr v-if="appointment.type !== 'MOBILE'">
                <th><i class="fa fa-map-marker"></i></th>
                <td>
                  <div class="pt-3" v-if="company && company.location">

                    {{ company.location.street ?? "" }}
                    {{ company.location.street2 ?? "" }}
                    {{ company.location.postalCode ? ", " + company.location.postalCode : "" }}
                    <br v-if="step !== 'companyInfo'">
                    {{ $t(company.location.city) ?? "" }}
                    {{ company.location.country ? ", " + $t(company.location.country) : "" }}
                    <br>
                    <a target="_blank" :href="'https://www.google.com/maps/dir//'+companyLocationSearch">
                      {{$t("View location")}}
                    </a>
                  </div>
                </td>
              </tr>
                  <tr v-else-if="appointment.type === 'MOBILE' && step !== 'selectAddress'">
                    <th><i class="fa fa-map-marker"></i></th>
                    <td>
                      <div class="pt-3" v-if="company && company.location">

                        {{ appointment.address.street ?? "" }}
                        {{ appointment.address.street2 ?? "" }}
                        {{ appointment.address.postalCode ? ", " + company.location.postalCode : "" }}
                        <br v-if="step !== 'companyInfo'">
                        {{ $t(appointment.address.city) ?? "" }}
                        {{ appointment.address.country ? ", " + $t(appointment.address.country) : "" }}

                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <div class="social-part"
               v-if="
               step === 'companyInfo' && (
               company.hasOwnProperty('facebook') && company.facebook !== null ||
               company.hasOwnProperty('instagram') && company.instagram !== null ||
               company.hasOwnProperty('website') && company.website !== null)"
          >
            <h3>{{$t("Follow us")}} </h3>

            <div class="social-items pb-3">
                <a v-if="company.hasOwnProperty('facebook') && company.facebook !== null" class="p-1" style="cursor: pointer" target="_blank" :href="maskSocialName(company.facebook, 'fb')">
                  <i style="font-size: 25px;" class="fab fa-facebook-square"></i>
                </a>

                <a v-if="company.hasOwnProperty('instagram') && company.instagram !== null" class="p-1" style="cursor: pointer" target="_blank" :href="maskSocialName(company.instagram, 'insta')">
                  <i style="font-size: 25px;" class="fab fa-instagram"></i>
                </a>
              <a v-if="company.hasOwnProperty('website') && company.website !== null" class="p-1" style="cursor: pointer" target="_blank" :href="maskUrl(company.website)">
                <i style="font-size: 25px;" class="fa fa-globe"></i>
              </a>
            </div>

          </div>

        </div>
          <div class="book-appointment-group" v-if="step === 'companyInfo'">
            <button
                type="button"
                :class="[
                  { 'text-white': !$store.state.companyColorIsLight},
                  {'text-dark': $store.state.companyColorIsLight}
              ]"
                class="btn btn-primary mt-2 book-appointment-class"
                :style="{
                  background: $store.state.companyColor ?? ''
                }"
                @click="next()"
            >
              {{ $t("Book an appointment") }}
            </button>
          </div>
        <div v-if="appointment.startTime !== null" class="apment-summary">
          <div class="font-weight-800 text-md" >{{ appointment.mStartDate.locale($store.state.locale).format("dddd, DD MMM YYYY [at] HH:mm a") }}</div>
          <div class="text-black-50">{{ convertMinsToHrsMins(appointment.duration) }} &nbsp; {{ $t("duration") }}</div>
        </div>
        <div v-if="appointment.employeeId !== null" class="apment-summary">
          <div class="title">
            <b>{{ $t("Employee") }}</b> <br>
            {{ appointment.employeeName }}
          </div>

        </div>
        <div v-if="appointment.services.length > 0" class="apment-summary">
<!--          <h5>Services ({{ appointment.services.length }})</h5>-->
          <div v-bind:key="service" class="row pb-3" v-for="service in appointment.services">
            <div class="col-8">
              <b>{{ service.name }}</b> <br />
              <span class="text-sm text-black-50">{{ convertMinsToHrsMins(service.duration) }}</span>
            </div>

            <div class="col-4 text-right">
            <service-price :cost="service.cost" :price-type="service.priceType" :special-price="service.specialPrice"></service-price>
            </div>
          </div>

          </div>
        <div class="apment-summary" v-if="appointment.type === 'MOBILE'">
          <div class="row">
            <div class="col-6">
              <b>{{ $t("Travel Cost") }}</b>
            </div>

            <div class="col-6 text-right">€{{ bookingSettings.travelFee }} </div>
          </div>
        </div>
        <div v-if="appointment.services.length > 0" class="apment-summary">
          <div class="row total">
            <div class="col-6">
              <b>{{ $t("Total") }}</b>
            </div>

            <div class="col-6 text-right">
              <template v-if="appointment.services.findIndex(a => a.priceType === 'FROM') !== -1">
                <span class="opacity-6 text-md">{{ $t("From")}} </span> <br>
              </template>
              <b>€{{ appointment.cost + (appointment.hasOwnProperty("travelFee") ? appointment.travelFee : 0) }}</b> <br>
              <b>{{convertMinsToHrsMins(appointment.duration)}}</b> <br>
            </div>
          </div>

          <div v-if="appointmentLoyaltyRewards > 0 && bookingSettings.hasOwnProperty('loyaltyProgramEnable') && bookingSettings.loyaltyProgramEnable" class="row">
            <div class="col-12 pt-3 text-muted text-sm">
              <i class="fa fa-info-circle"></i>
            {{ $t("By booking this appointment you will earn loyalty points", { variable: (appointmentLoyaltyRewards*100).toFixed(0)})}}
              </div>
          </div>
        </div>
        </template>
      </div>
      <photo-slider v-if="step === 'companyInfo' && !loadingCompany && company.hasOwnProperty('gallery') && company.gallery.length > 0" class="col-md-6 hide-on-mobile" :photos="company.hasOwnProperty('gallery') ? company.gallery.map(a => a.url) : []"></photo-slider>
      <company-photo v-if="step === 'companyInfo'" :photo="company.hasOwnProperty('gallery') && company.gallery.length > 0 ? company.gallery[0].url : ''" ></company-photo>

      <div class="stepperControlls-mobile">
        <!-- Stepper Controls -->
       <div class="col-6 float-left text-left" v-if="['services'].includes(step) && appointment.services.length > 0">
         <div class="m-3">

           <div class="col-xs-6 float-left">
             Cost:
             <template v-if="appointment.services.findIndex(a => a.priceType === 'FROM') !== -1">
               <span class="opacity-6 text-sm text-uppercase pr-1">{{ $t("From")}} </span>
             </template>
             <b>€{{ appointment.cost + (appointment.hasOwnProperty("travelFee") ? appointment.travelFee : 0) }}</b>
           </div>
           <div class="col-xs-6 float-left">
           Duration: <b>{{convertMinsToHrsMins(appointment.duration, false)}}</b>
           </div>
         </div>
       </div>
        <div class="col-6 float-left">
        <button
            v-if="
              (['services'].includes(step) && appointment.services.length > 0) ||
               (!this.v$.appointment.address.$invalid && ['selectAddress'].includes(step))
            "
            type="button"
            class="btn btn-primary ml-2 m-3"
            @click="next()"
        >
          {{ $t("Next") }}
        </button>
        </div>
        <submit-button v-if="step === 'registerStep'" :alertMessage="alertMessage" @clicked="registerStepSubmit()" :state="registerLoadingProcess" size="md" class="ml-2 m-3">
          {{ $t("Next") }}</submit-button>
<!--          <template v-if="canRedeem && step === 'noteConfirm'">-->
<!--            <submit-button @clicked="redeem()" style="float: right;" class=" sumbit-appt-mobile" :alertMessage="errorMessage" :state="loadingRedeemProcess"  size="md">-->
<!--              {{ $t("Redeem Free") }}</submit-button>-->
<!--          </template>-->
        <submit-button v-if="step === 'noteConfirm'" :state="loadingProcess" class="sumbit-appt-mobile" @clicked="redeemOrNot()" size="md" type="primary">
          {{ $t("Submit") }}</submit-button>
      </div>
    </div>
    <modal :show="modalBool" @close="modalBool = false">
      <template v-slot:header>
        <h2 class="modal-title" id="exampleModalLabel">
          {{ modalTitle }}
        </h2>
      </template>
      <div>
        {{ modalDescription }} <br>
        <div class="row total pt-4">
          <div class="col-sm-12 col-md-6">
            <span>{{ $t("Your loyalty balance:") }}</span>
          </div>

          <div class="col-sm-12 col-md-6  text-right left-on-small">
            <b v-if="this.clientData !== null"> {{ this.clientData.hasOwnProperty("loyaltyPoints") ? (this.clientData.loyaltyPoints * 100).toFixed(0) : 0 }} {{ $t("loyalty points")}}</b> <br>
          </div>
        </div>
        <div class="row total">
          <div class="col-sm-12 col-md-6">
            <span>{{ $t("Appointment cost:") }}</span>
          </div>

          <div class="col-sm-12 col-md-6 text-right left-on-small">
            <b>{{(this.appointment.cost * 100).toFixed(0) }} {{ $t("loyalty points")}}</b> <br>
          </div>
        </div>
        <div class="row total">


          <div class="col-12 text-right pt-4 left-on-small" v-if="this.$store.state.user.clientData !== null">
            {{$t("Your remaining balance will be") }} {{((this.$store.state.user.clientData.loyaltyPoints-this.appointment.cost) * 100).toFixed(0)}} {{ $t("points loyalty")}}<br>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alertMessage="errorMessage"
            :state="loadingRedeemProcess"
            @clicked="redeem()"
            type="primary"
        >{{ $t("Redeem")}}</submit-button>
        <submit-button
            :alertMessage="errorMessage"
            :state="loadingProcess"
            @clicked="normalSubmit()"
            type="primary"
        >{{$t("Book")}}</submit-button>
      </template>
    </modal>
  </div>
</template>

<script>
import BaseButton from "../../components/BaseButton";
// import Datepicker from '@vuepic/vue-datepicker';
import moment from "moment";
import FirebaseFunctionsHelper from "../../plugins/firebaseFunctionsHelper";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import BaseInput from "../../components/BaseInput";
import SubmitButton from "../../components/SubmitButton";
import useVuelidate from "@vuelidate/core";
import {required, sameAs, email, helpers} from "@vuelidate/validators";
import {store} from "@/store";
import {
  convertMinsToHrsMins,
  getCompanyTypes,
  groupBy,
  setImage,
  setTitle,
  setDescription,
  getValueOrNull
} from "@/directives/helper";
import ServicePrice from "@/views/Components/ServicePrice";
import CompanyPhoto from "@/views/QuickBook/CompanyPhoto";
import WorkingHours from "@/views/QuickBook/WorkingHours";
import ExtraFeatures from "@/views/QuickBook/ExtraFeatures";
import ExtraInfo from "@/views/QuickBook/ExtraInfo";
import PhotoSlider from "@/views/Components/PhotoSlider";
import { logEvent } from "firebase/analytics";
import {analytics} from "@/main";
import tinycolor from "tinycolor2";
import ScrollDatePicker from "@/components/ScrollDatePicker";
export default {
  name: "QuickBook",
  components: {
    ScrollDatePicker,
    PhotoSlider,
    ExtraInfo,
    ExtraFeatures, WorkingHours, CompanyPhoto, ServicePrice, SubmitButton, BaseInput, BaseButton },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      phoneNumber: {
        required,
        mustHaveCyPrefix: helpers.withMessage(this.$t('Phone number must be a valid CY number.'), helpers.regex(/^[0-9]{8}$/)),
      },
      appointment: {
        address: {
          street: {
            required
          },
          city: {
            required
          },
          country: {
            required
          },
          postalCode: {
            required,
          },
        }
      },
      userDetails: {
        firstName: {
          required
        },
        email: {
          required,
          email
        },
        lastName: {
          required
        },
        isPolicyChecked: {
          sameAsRawValue: helpers.withMessage('Please read & check policies.', sameAs(true))
        },
      }

    };
  },
  data() {
    return {
      days: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
      disabledDates: [],
      appointmentLink: "",
      loadingServices: false,
      loadingCompany: true,
      workingHours: {},
      errorMessage: "",
      modalBool: false,
      modalTitle: "",
      modalDescription: "",
      modalAction: null,
      modalAction2: null,
      showAlert: false,
      redeemOrNotLoading: '',
      loadingRedeemProcess: '',
      servicesGrouped: {},
      genderOptions: [
        {value: "", text: "Select Gender"},
        {value: "MALE", text: this.$t("Male")},
        {value: "FEMALE", text: this.$t("Female")},
        {value: "OTHER", text: this.$t("Other")}
      ],
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        isPolicyChecked: false,
        gender: ""
      },
      clientData: null,
      registerLoadingProcess: "",
      loadingProcess: "",
      signInLoader: false,
      sendingCodeStatus: false,
      codeSent: false,
      phoneNumber: "",
      code: "",
      recaptchaVerifier: null,
      confirmationResult: null,
      gettingSlots: false,
      availableSlots: { list: [], isClosed: false},
      lastAvailableDate: null,
      isSafari: false,
      bookingSettings: {
        loyaltyProgramEnable: false,
        travelTime: 0,
        travelFee: 0
      },
      fetchingEmployees: false,
      employees: [],
      company: {},
      companyLocationSearch: "",
      step: "companyInfo",
      progress: 0,
      services: [],
      loading: true,
      servicesLastItem: null,
      companyTypes: {},
      cities: ["LIMASSOL", "NICOSIA", "LARNACA", "PAPHOS", "FAMAGUSTA"],
      appointment: {
        saveClientAddress: false,
        notes: "",
        mStartDate: moment(),
        startTime: null,
        startDate: moment().add('days', 1).toDate(),
        cost: 0,
        duration: 0,
        services: [],
        type: "",
        employeeId: null,
        employeeName: "",
        address: {
          country: "CY",
          city: "",
          postalCode: "",
          street: "",
          street2: "",
          lat: null,
          long: null
        }
      },
    };
  },
  watch: {
    // "authUserReady":  function (newVal, oldVal) {
    //   if(newVal === false){
    //     this.step = 'authStep';
    //     this.calculateProgressBar()
    //   }
    // },
    "step": function (newVal) {
      if(newVal !== "companyInfo") {
        this.$store.state.companyActive = this.company.companyName
      } else {
        this.$store.state.companyActive = null
      }
      if(this.$store.state.user.data !== null && newVal === "registerStep") {
        this.next()
      }
    },
    // whenever startDate changes, this function will run
    "appointment.startDate": function () {
      this.dateSelected()
    }
  },
  computed: {
    appointmentLoyaltyRewards() {
      // eslint-disable-next-line no-prototype-builtins
      let appointmentCost = this.appointment.cost + (this.appointment.hasOwnProperty("travelFee") ? this.appointment.travelFee : 0)
      // eslint-disable-next-line no-prototype-builtins
      if(!this.bookingSettings.hasOwnProperty('loyaltyPercentage')){
        return 0
      }

      let percentage =  this.bookingSettings.loyaltyPercentage ?? 0
      return  (percentage/100) * appointmentCost
    },
    canRedeem() {
      // eslint-disable-next-line no-prototype-builtins
      if(this.clientData === null){
        return false
      }
      // eslint-disable-next-line no-prototype-builtins
      if(!(this.clientData.hasOwnProperty("loyaltyPoints"))){
        return false
      }
      // eslint-disable-next-line no-prototype-builtins
        return this.clientData.loyaltyPoints >= this.appointment.cost;

    },
      // authUserReady: function () {
      //   // eslint-disable-next-line no-prototype-builtins
      //   return this.$store.state.user.hasOwnProperty("claims") && this.$store.state.user.claims !== null
      // },
    steps() {
      if (this.company && this.company.serviceLocation === "BUSINESS") {
        return ["selectType", "services", "selectEmployee", "selectDate", "noteConfirm"];
      } else {
        return ["selectType", "selectAddress", "services", "selectEmployee", "selectDate", "noteConfirm"];
      }
    }
  },
  startAgain() {
    this.step = "services"
    this.calculateProgressBar()
  },
  async mounted() {
    this.isSafari = window.safari !== undefined;
    this.$store.state.companyActive = null
    await this.getCompany();
    await this.getCompanyBookingSettings();
    this.setTitle(this.company.companyName)
    this.setDescription(this.company.description)
    this.setImage(this.company.gallery.length > 0 ? this.company.gallery[0].url : "")
    this.set
    if(this.$store.state.user.claims !== null){
      this.clientData = await store.dispatch("fetchClient", {companyId: this.$route.params.id, userId: this.$store.state.user.claims.user_id});
    }
    // eslint-disable-next-line no-prototype-builtins
    // if ( this.$store.state.user.hasOwnProperty("claims") && this.$store.state.user.claims !== null ) {
    //   this.next();
    // } else {
    //   this.step = "authStep";
    // }
    if (Object.keys(this.company).length === 0) {
      this.company = null;
      await this.$router.push({
        name: 'error',
        params: { desc: 'The company you are trying to book does not exist. If you think otherwise contact us or the company directly.' }
      })
      return
    }
    if (this.company.isOnline !== true) {
      this.company = null;
      await this.$router.push({
        name: 'error',
        params: { desc: 'The company you are trying to book with is not available for online bookings.' }
      })
    }

    this.companyTypes = await getCompanyTypes()
    this.step = 'companyInfo'
  },
  methods: {
    setTitle,
    setDescription,
    setImage,
    maskSocialName(name, socialType){
      if (name.indexOf("http://") === -1 && name.indexOf("https://") === -1 && name.indexOf("www.")) {
        return socialType === 'fb' ? 'https://www.facebook.com/'+name : 'https://www.instagram.com/'+name
      }
      return name
    },
    maskUrl(url){
      if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
        return 'http://'+url
      }
      return url
    },
    convertMinsToHrsMins,
    resetAuthForm(){
      this.codeSent = false
      this.showAlert = false
    },
    startAgain() {
      this.appointment = {
        notes: "",
        mStartDate: moment(),
        startTime: null,
        startDate: moment().add('days', 1).toDate(),
        cost: 0,
        duration: 0,
        services: [],
        type: "",
      };
      this.loadingProcess = "";
      this.loadingRedeemProcess = ""
      this.availableSlots = { list: [], isClosed: false};
      this.step = 'loadingStep'
      this.next()
    },
    getNextUser() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
            this.fetchServices()
        }
      }
    },
    registerStepSubmit(){
      // submit user details 'register him in users'
      this.v$.$touch();

      if (this.v$.userDetails.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
      this.registerLoadingProcess = 'processing';

      // eslint-disable-next-line no-unreachable
      FirebaseFunctionsHelper.callFunction(
          "clientRegistration",
          // eslint-disable-next-line no-undef
          this.userDetails
      ).then(async (result) => {
        // eslint-disable-next-line no-prototype-builtins
        if (result && result.hasOwnProperty("message")) {
          this.alertMessage = result.message;
          this.registerLoadingProcess = "failed";
          return;
        }
        this.registerLoadingProcess = "done";
        await store.dispatch("fetchUser", {uid: this.$store.state.user.claims.user_id});
        this.next()
      });
    },
    async redeem() {
      this.loadingRedeemProcess = "processing";
      this.loadingProcess = "isLoading";
      this.appointment['redeemLoyaltyPoints'] = true
      let result = await this.submit();
      // eslint-disable-next-line no-prototype-builtins
      if (result && result.hasOwnProperty("message")) {
        this.alertMessage = result.message;
        this.loadingRedeemProcess = "failed";
        this.loadingProcess = "";
        return true
      }

      logEvent ( analytics, 'appointment_new', {
        companyId: this.$route.params.id,
        type: this.appointment.type,
      });

      // need to fetch him to refresh loyalty points
      // await store.dispatch("fetchClient", {companyId: this.$route.params.id, userId: this.$store.state.user.claims.user_id});
      this.modalBool = false
      this.loadingRedeemProcess = "done";
      this.next();
    },
    showModal(title, description) {
      this.modalTitle = title;
      this.modalDescription = description;
      this.modalBool = true;
    },
    redeemOrNot() {

      if(this.canRedeem){
        this.showModal(this.$t('Looks like you can book this free'),
            this.$t('Your loyalty points are enough to book this appointment free.')
        )
      } else {
        // this.redeemOrNotLoading = true
        this.normalSubmit()
      }
    },
    async normalSubmit() {
      this.loadingProcess = "processing";
      this.loadingRedeemProcess = "isLoading";
      let result = await this.submit();
      // eslint-disable-next-line no-prototype-builtins
      if (result && result.hasOwnProperty("message")) {
        this.alertMessage = result.message;
        this.loadingProcess = "failed";
        this.loadingRedeemProcess = ""
        return true
      }
      if(result !== null) {
        this.appointmentLink = '/a/'+result
      }
      this.modalBool = false
      this.loadingProcess = "done";
      this.next();
    },
    submit(){
      //create appointment
      if(this.$store.state.user.claims === null){
        this.step = "authStep"
        return
      }
      let data = JSON.parse(JSON.stringify(this.appointment));
      data["companyId"] = this.$route.params.id;
      data["startTime"] = this.appointment.mStartDate.format("yyyy-MM-DD HH:mm:ss Z");
      data["clientId"] = this.$store.state.user.claims.user_id;
      data["clientName"] = this.$store.state.user.data.firstName +  " " + this.$store.state.user.data.lastName

      // id, name, duration, cost
      data["services"] = data["services"].map((service) => {
        return {
          "id": service.id,
          "name": service.name,
          "type": service.type,
          "duration": service.duration,
          "cost": service.cost,
          "priceType": service.priceType,
          "specialPrice": service.specialPrice,
          "companyType": service.companyType,
          "extraTime": service.extraTime ?? null,
          "excludedDays": service.excludedDays ?? null
        }
      });

      // eslint-disable-next-line no-unreachable
      return FirebaseFunctionsHelper.callFunction(
        "addAppointment",
        // eslint-disable-next-line no-undef
        data
      ).then((result) => {
        return result;
      });
    },
    getCode() {
      let self = this;
      // this.clearAlert();
      let userPhone = ""
      this.v$.phoneNumber.$touch();
      if (this.v$.phoneNumber.$invalid) {
        return;
      } else {
        userPhone = "+357" + this.phoneNumber
      }


      this.sendingCodeStatus = true;
      const auth = getAuth(this.fireApp);
      if(this.recaptchaVerifier === null) {
        this.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                console.log("response", response);
              },
            }, auth);
      }

      signInWithPhoneNumber(auth, userPhone, this.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          self.codeSent = true;
          self.initAlert("info", "The 6-digit code has been sent to the mobile number given.");
          this.sendingCodeStatus = false;
          self.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          this.sendingCodeStatus = false;
          let message = error.message;
          if(error.code === "auth/invalid-phone-number") {
            message = "The phone number given is not valid.";
          }
          self.initAlert("danger", message);
          // Error; SMS not sent
        });
    },
    signIn() {
      let self = this;
      this.clearAlert()
      this.signInLoader = true;
      this.confirmationResult
        .confirm(this.code)
        .then(async (result) => {
          // User signed in successfully.

          self.$store.commit('SET_LOGGED_IN', true)
          const user = result.user;
          await store.dispatch("fetchUser", user);
          self.clientData = await store.dispatch("fetchClient", {companyId: self.$route.params.id, userId: user.uid});
          self.code = ""
          self.codeSent = false
          self.phoneNumber = ""
          self.signInLoader = false
          self.step = "authStep"
          self.next()
          await self.getCompanyBookingSettings();
        })
        .catch((error) => {
          this.signInLoader = false;
          // User couldn't sign in (bad verification code?)
          let message = error.message;
          if (error.code === "auth/invalid-verification-code") {
            message = "Verification code is invalid.";
          }
          self.initAlert("danger", message);
        });
    },
    initAlert(type, message) {
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    clearAlert() {
      this.showAlert = false;
      this.alertType = "";
      this.alertMessage = "";
    },
    dateSelected() {
      this.appointment.startTime = null
      this.appointment.mStartDate = moment(this.appointment.startDate);
      if(this.disabledDates.includes(this.appointment.mStartDate.format('yyyyMMDD'))){
        this.appointment.startDate = this.appointment.mStartDate.add(1,"day").toDate()
        this.dateSelected()
      }
      this.availableSlots = { list: [], isClosed: false};
      this.gettingSlots = true;
      this.getAvailableHours()
    },
    async getAvailableHours() {
      let data = {
        companyId: this.$route.params.id,
        duration: this.appointment.duration,
        employeeId: this.appointment.employeeId,
        startDate: moment(this.appointment.startDate).locale(this.$store.state.locale).format("yyyy-MM-DD HH:mm:ss Z"),
        serviceLocation: this.appointment.type,
      };

      FirebaseFunctionsHelper.callFunction(
        "getAppointmentAvailableTimes",
        // eslint-disable-next-line no-undef
        data
      ).then((result) => {
        this.gettingSlots = false;
        // eslint-disable-next-line no-prototype-builtins
        if (result && result.hasOwnProperty("message")) {
          this.showError = true;
          this.alertMessage = result.message;
          return;
        }

        // console.log("availableSlots", result)

        var times = []
        result.list.forEach( time => {
          times.push(moment(time, "yyyy-MM-DD HH:mm:ss Z").format("HH:mm"))
        })
        this.availableSlots.isClosed = result.isClosed;
        this.availableSlots.list = times
      });

    },
    async getCompany() {
      let self = this;
      await this.$store.dispatch("fetchCompany", { id: this.$route.params.id })
        .then((data) => {
          if (data) {
            self.company = data;
            self.$store.state.companyColor = data.companyColor ?? null
            self.$store.state.companyColorIsLight = tinycolor(data.companyColor ?? null).isLight()
            if (self.step !== "companyInfo") {
              self.$store.state.companyActive = data.companyName
            }
            self.genCompanyLocationSearchString()
          } else {
            self.step = "error";
            self.errorMessage = "No company found. If you think the link is correct please try to refresh or contact our support team."
          }
        }).catch((error) => {
          console.error("error", error)
            self.step = "error";
            self.errorMessage = "No company found. If you think the link is correct please try to refresh or contact our support team."

          });
    },
    genCompanyLocationSearchString(){
      let locationString = "";


      // eslint-disable-next-line no-prototype-builtins
      if(this.company.location.hasOwnProperty("coords")){
        this.companyLocationSearch = this.company.location.coords.latitude + "," + this.company.location.coords.longitude
        return
      }

      // eslint-disable-next-line no-prototype-builtins
      if(this.company.location.hasOwnProperty("street")){
        locationString += this.company.location.street
      }

      // eslint-disable-next-line no-prototype-builtins
      if(this.company.location.hasOwnProperty("street2")){
        locationString += this.company.location.street2
      }

      // // eslint-disable-next-line no-prototype-builtins
      // if(this.company.location.hasOwnProperty("city")){
      //   locationString += ", "+ this.company.location.city
      // }
      // // eslint-disable-next-line no-prototype-builtins
      // if(this.company.location.hasOwnProperty("country")){
      //   locationString += ", "+ this.company.location.country
      // }

      this.companyLocationSearch = locationString
    },
    async getCompanyBookingSettings() {
      let self = this;
      await this.$store
        .dispatch("fetchBookingSettings", { id: this.$route.params.id })
        .then((data) => {
          if (data) {
            self.bookingSettings = data;
            self.lastAvailableDate = moment().add(self.bookingSettings.futureBooking, 'minutes').toDate()
            self.loadingCompany = false
            }
        });
    },
    selectTime(time) {
      this.appointment.startTime = time
      let dateee = this.appointment.startDate.toISOString().split('T').shift()
      this.appointment.mStartDate = moment(dateee + " " + time)
      setTimeout(() => {
        this.next();
      }, 500);
    },
    selectEmployee(employee) {
      this.appointment.employeeName = employee.firstName + " " + employee.lastName;
      this.appointment.employeeId = employee.id;
      this.next();
    },
    selectService(service) {
      const index = this.appointment.services.findIndex( s => s.id === service.id);

      let serviceCost = 0;
      if(service.priceType !== 'FREE') {
            serviceCost = service.cost
        // eslint-disable-next-line no-prototype-builtins
        if(service.hasOwnProperty("specialPrice") && service.specialPrice !== null && service.specialPrice < service.cost){
          serviceCost = service.specialPrice
        }
      }

      if (index > -1) {
        this.appointment.cost -= serviceCost;
        this.appointment.duration -= service.duration;
        this.appointment.services.splice(index, 1);
      } else {
        this.appointment.cost += serviceCost;
        this.appointment.duration += service.duration;
        this.appointment.services.push(service);
      }
    },
    calcDisabledDays() {
      let excludedDays = []
      this.appointment.services.forEach( serv => {
        // eslint-disable-next-line no-prototype-builtins
        if (serv.hasOwnProperty("excludedDays")){
          var result = Object.keys(serv.excludedDays).map((key) => serv.excludedDays[key])
          if (result.length > 0) {
            excludedDays = excludedDays.concat(result)
          }
        }
      })

      for (let d = moment(); d.toDate() <= this.lastAvailableDate; d.add(1, "day")) {
        if(excludedDays.includes(d.locale('en').format('dddd').toUpperCase())){
          this.disabledDates.push(d.format("yyyyMMDD"))
        }
      }
    },
    removeDups(arr) {
        var seen = {};
        var ret_arr = [];
        for (var i = 0; i < arr.length; i++) {
          if (!(arr[i] in seen)) {
            ret_arr.push(arr[i]);
            seen[arr[i]] = true;
          }
        }
        return ret_arr;
    },
    selectType(type) {
      this.appointment.type = type;
      if (type ==="MOBILE") {
        this.appointment.travelFee = this.bookingSettings.travelFee
      }
      this.next();
    },
    // sortDays(a, b) {
    //   const days = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
    //   a = days.indexOf(a);
    //   b = days.indexOf(b);
    //   return a < b ? 0 : 1;
    // },
    async fetchServices(refresh = false) {
      let self = this
      if(refresh){
        this.loadingServices = true
        self.services = []
        self.servicesLastItem = null
      }

      await this.$store.dispatch("fetchCompanyServices", { id: this.$route.params.id, type: this.appointment.type, servicesLastItem: this.servicesLastItem })
        .then((data) => {
          self.loadingServices = false
          self.servicesLastItem = data.servicesLastItem

          data.items.forEach( item => {
            let service = item
            let exclDays = getValueOrNull(service, "excludedDays")
            console.log(exclDays)
            if (exclDays){
              service["excludedDays"] =  service["excludedDays"].sort(
                  (a, b) =>
                      this.days.indexOf(a) - this.days.indexOf(b)
              );
              console.log("exclDays sorted",  service["excludedDays"].sort(
                  (a, b) =>
                      this.days.indexOf(a) - this.days.indexOf(b)
              ))
            }
            let index = self.services.indexOf(s => s.id === service.id)
            if (index === -1){
              self.services.push(service)
            } else {
              self.services[index] = service
            }
          })
          self.servicesGrouped = groupBy(self.services,'categoryId')

        })
    },
    async logout() {
      this.step = "authStep"
      this.progress = 0
      let result = await this.$store.dispatch("signOut");
      console.log("logout result", result)
      // console.log("this.$route logout", self.$router)
      // if ( self.$route.name !== "Company") {
      //   self.$router.push({ path: "/" });
      // }
    },
    async getAvailableEmployees() {
      let self = this;
      this.fetchingEmployees = true
      await this.$store.dispatch("fetchAvailableEmployees", { id: this.$route.params.id, services: this.appointment.services })
        .then((data) => {
          self.fetchingEmployees = false
          self.employees = data;
          // if (data != null && data.length === 1) {
          //   self.selectEmployee(data[1])
          // }
        })
    },
    async fetchClientPrivate() {
      let self = this
      await this.$store.dispatch("fetchUserPrivate").then((data) => {
        if (data) {
          self.userPrivate = data;
          // eslint-disable-next-line no-prototype-builtins
          if(data.hasOwnProperty("location")) {
            self.appointment.address.street = data.location.street
            self.appointment.address.street2 = data.location.street2
            self.appointment.address.city = data.location.city
            self.appointment.address.postalCode = data.location.postalCode
            self.appointment.address.country = data.location.country
            // eslint-disable-next-line no-prototype-builtins
            // if (data.location.hasOwnProperty("coords") && data.location.coords) {
            //   console.log("data.address.coords.longitude", data.location.coords.longitude)
            //   console.log("data.address.coords.latitude", data.location.coords.latitude)
            // }

          }
        }
      })

    },
    next() {
      switch (this.step) {
        case "companyInfo":
          if (this.company.serviceLocation === "BUSINESS_AND_MOBILE"){
            this.step = "selectType";
          } else if (this.company.serviceLocation === "BUSINESS") {
            this.appointment.type = "BUSINESS";

            this.step = "services";
            this.fetchServices();
          } else if (this.company.serviceLocation === "MOBILE") {
            this.appointment.type = "MOBILE"
            this.step = "selectAddress";
            this.fetchClientPrivate()
          }
          break;
        case "loadingStep":
        case "authStep":
        case "registerStep":
          if(this.$store.state.user.data === null && this.$store.state.user.claims !== null && this.step !== "registerStep"){
            this.step = "registerStep"
            return
          }

          if(this.$store.state.user.data === null && this.$store.state.user.claims === null){
            this.step = "authStep"
            return
          }

          this.step = "noteConfirm"
          break;
        case "selectType":
          if (this.appointment.type === "MOBILE") {
            this.step = "selectAddress";
            this.fetchClientPrivate()
          } else {
            this.step = "services";
            this.fetchServices(true);
          }
          break;
        case "selectEmployee":
          this.step = "selectDate";
          this.dateSelected();
          break;
        case "selectAddress":
          this.step = "services";
          this.fetchServices(true);
          break;
        case "selectDate":
          if(this.$store.state.user.data === null){
            this.step = "authStep"
            return
          }
          this.step = "noteConfirm";
          break;
        case "noteConfirm":
          this.step = "thankYou";
          break;
        case "services":
          this.step = "selectEmployee";
          this.calcDisabledDays()
          this.getAvailableEmployees();
          break;
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.calculateProgressBar();
    },
    calculateProgressBar(){
      const index = this.steps.findIndex( s => s === this.step);
      this.progress = (index) * (100 / this.steps.length);
    },
    previous() {
      switch (this.step) {
        case "authStep":
          this.step = "selectDate";
          return;
        case "selectType":
          this.step = "companyInfo";
          return;
        case "selectEmployee":
          this.step = "services";
          // this.fetchServices();
          break;
        case "selectDate":
          this.step = "selectEmployee";
          this.getAvailableEmployees();
          break;
        case "services":
          if (this.appointment.type === "MOBILE") {
            this.step = "selectAddress";
          } else if (this.bookingSettings.serviceLocation === "BUSINESS_AND_MOBILE"){
            this.step = "selectType"
          }
          break;
        case "selectAddress":
          this.step = "selectType";
          break;
        case "noteConfirm":
          this.step = "selectDate";
      }
      this.calculateProgressBar();
    },
  },
};
</script>

<style scoped>
html,
body,
#wtf {
  height: 100%;
}

.row {
  /*   width: 100%; */
}

.stepper-wrapper {
  margin: 0 auto;
}

.btn.option-button {
  border: 1px solid #8080801f;
  margin-bottom: 6px;
  font-size: 20px;
  text-align: left;
}

.btn.option-button .service-title {
  font-weight: 800;
}

.stepperControlls {
  float: right;
  padding: 10px;
}

i.fas.fa-check-circle {
  color: green;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  color: black;
}

.apment-summary {
  background: rgb(0 151 164 / 15%);
  padding: 10px 32px 10px;
  /*border-radius: 0 0 8px 8px;*/
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
}

.apment-summary:first-child {
  background: rgb(0 151 164 / 15%);
  padding: 10px 32px 10px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
}

.apment-summary:last-child {
  background: rgb(0 151 164 / 15%);
  padding: 10px 32px 10px;
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
}

.row.p-2.total {
  font-size: 20px;
}
.image-cropper img.rounded {
  clip-path: circle();
  width: 100%;
  max-width: 100px;
}
.dp__cell_inner.dp__pointer.dp__active_date {
  background: #00a4a4;
}

.dp__overlay_cell_active {
  background: #00a4a4;
}

.dp__today {
  border-color: #00a4a4;
}


button.btn.btn-link.btn:focus, button.btn.btn-link.btn.focus {
  box-shadow: none;
}

.sumbit-appt-mobile{
  padding: 15px;
}

@media (min-width:670px) {
  .sumbit-appt-mobile{
    display: none;
  }
}
@media (max-width:670px) {
  .row.col-12.col-md-10.pt-4 {
    height: 100%;
  }
  button.btn.btn-link.float-left.p-2.logout {
    margin: 4px 0;
  }
  .row.col-md-6.pt-2.availableSlots {
    margin: 0 auto;
  }
  .sumbit-appt{
    display: none;
  }

  .hide-on-mobile{
    display: none;
  }
    .summary-box {
      padding-bottom: 90px;
    }
}
@media (min-width: 768px) {
  .only-on-mobile{
    display: none;
  }
  .stepperControlls-mobile{
    display: none;
  }
  .stepperControlls{
    padding:0;
  }
  .service-btn-check{
    max-width: 65px
  }
}

@media (max-width: 768px) {

  button.book-appointment-class {
    width: 100%;
    /* border-top-left-radius: 0; */
    /* margin-top: 0 !important; */
    /* border-top-right-radius: 0; */
    font-size: 17px;
    text-align: center;
    margin: 0 auto;
    width: calc(100% - 20px) !important;
    margin-bottom: 10px;
  }

  .book-appointment-group {
    background: white;
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .mobile-no-padding {
    padding: 0 !important;
  }

  .mobile-no-margin{
    margin: 0 !important;
  }

  .left-on-small{
    text-align: left !important;
  }

  .sub-title.text-right{
    text-align: left !important;
  }
  .stepperControlls-mobile {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    left: 0;
    text-align: right;
    box-shadow: rgb(0 0 0 / 46%) 0 7px 15px 0;
  }
  .stepperControlls {
    display: none;
  }
  .summary-box {
    display: none;
  }

  footer {
    background: red;
    display: none !important;
  }
}

.form-group.has-danger.has-label.has-danger ::placeholder {
  color: #0000007a;
}

.service-description{
  font-weight: normal;
}

button.book-appointment-class {
  width: 100%;
  /*border-top-left-radius: 0;*/
  /*margin-top: 0 !important;*/
  /*border-top-right-radius: 0;*/
  font-size: 17px;
}

.white-bg {
  background: rgb(255 255 255 / 86%) !important;
}

.phone-input .input-group-prepend .input-group-text {
  padding-right: 5px;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

/*.apment-summary.mobile-no-margin.white-bg {*/
/*  max-height: calc(100vh - 91px - 48px - 96px - 25px);*/
/*  overflow-y: scroll;*/
/*  vertical-align: top;*/
/*}*/
</style>
