import { getFunctions, httpsCallable } from "firebase/functions";
import { fireApp } from "@/main";
const FirebaseFunctionsHelper = {
    callFunction(name, data) {
        let functions = getFunctions(fireApp, 'europe-west3');
        const addMessage = httpsCallable(functions, name);
        return addMessage(data)
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                return result.data;
            })
            .catch((error) => {
                // Getting the Error details.
                return error;
            });
    },
};
export default FirebaseFunctionsHelper;
