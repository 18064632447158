<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-3 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center col-xl-8">
        <div class="row">
          <div class="">
            <base-button hover type="link" class="row  text-white text-lg mt-2" style="box-shadow:none!important;" @click="$router.go(-1)">
              <i class="fa fa-chevron-left"></i> {{ $t("Back")}}
            </base-button>
            <h1 class=" display-2 text-white ">{{ $t("Hello") }} {{$store.state.user.data.hasOwnProperty("firstName") ? $store.state.user.data.firstName: "" }}</h1>
                        <p class="text-white m-0 font-weight-400">
                          {{ $t("This is your profile page.")}}
                        </p>
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row justify-content-md-center">



        <div class="col-xl-8 order-xl-2">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("Personal Details")}}</h3>
                  </div>
                  <div class="col-4 text-right">
<!--                    <base-button @click="getUser" class="btn btn-sm btn-primary">{{ $t("Refresh")}}</base-button>-->
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">{{ $t("User information")}}</h6>
              <div class="pl-lg-4">

                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('First name')"
                      placeholder="First name"
                      input-classes="form-control-alternative"
                      v-model="model.firstName"
                      :valid="!v$.model.firstName.$invalid"
                      :error="v$.model.firstName.$errors.map( err => { return err.$message }).join()"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('Last name')"
                      placeholder="Last name"
                      input-classes="form-control-alternative"
                      v-model="model.lastName"
                      :valid="!v$.model.lastName.$invalid"
                      :error="v$.model.lastName.$errors.map( err => { return err.$message }).join()"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                        alternative=""
                        :label="$t('Contact Email')"
                        placeholder="Contact Email"
                        input-classes="form-control-alternative"
                        v-model="modelPrivate.email"
                        :valid="!v$.modelPrivate.email.$invalid"
                        :error="v$.modelPrivate.email.$errors.map( err => { return err.$message }).join()"
                    />

                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{ $t("Gender")}}</label>
                      <select class="form-control  form-control-alternative"  name="gender" id="gender" v-model="modelPrivate.gender">
                        <option :key="option.value" v-for="option in genderOptions" :value="option.value"> {{option.text}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

<!--              <hr class="my-4" />-->
              <template v-if="modelPrivate.hasOwnProperty('location')">
                <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">{{ $t("Address (optional)") }}</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                        alternative=""
                        :label="$t('Street')"
                        :placeholder="$t('Street')"
                        input-classes="form-control-alternative"
                        v-model="modelPrivate.location.street"

                    />
<!--                    :valid="!v$.modelPrivate.location.street.$invalid"-->
<!--                    :error="v$.modelPrivate.location.street.$errors.map( err => { return err.$message }).join()"-->
                  </div>
                  <div class="col-md-6">
                    <base-input
                        alternative=""
                        :label="$t('Street')"
                        :placeholder="$t('Street 2')"
                        input-classes="form-control-alternative"
                        v-model="modelPrivate.location.street2"
                    />
                  </div>
                </div>
                <div class="row">
                <div class="col-lg-4">
<!--:class="{'has-danger': v$.modelPrivate.location.city.$invalid }"-->
                  <div class="form-group has-label" >
                    <label class="form-control-label">{{$t('City')}}</label>
                    <select class="form-control form-control-alternative" :name="$t('City')" id="city" v-model="modelPrivate.location.city">
                      <option value=""> {{ $t("Select City") }} </option>
                      <option :key="city" v-for="city in cities" :value="city"> {{$t(city)}}</option>
                    </select>

<!--                    <div v-if="v$.modelPrivate.location.city.$invalid" class="text-danger invalid-feedback" style="display: block;">-->
<!--                      {{v$.modelPrivate.location.city.$errors.map( err => { return err.$message })[0]}}-->
<!--                    </div>-->

                  </div>

                  <!--              <base-input-->
                  <!--                  alternative=""-->
                  <!--                  label="City"-->
                  <!--                  placeholder="City"-->
                  <!--                  input-classes="form-control-alternative"-->
                  <!--                  v-model="appointment.address.city"-->
                  <!--                  :valid="!v$.appointment.address.city.$invalid"-->
                  <!--                  :error="v$.appointment.address.city.$errors.map( err => { return err.$message }).join()"-->

                  <!--              />-->
                </div>
                <div class="col-lg-4">
                  <base-input
                      disabled=""
                      alternative=""
                      :label="$t('Country')"
                      :placeholder="$t('Country')"
                      input-classes="form-control-alternative"
                      v-model="staticCountry"

                  />
<!--                  :valid="!v$.modelPrivate.location.country.$invalid"-->
<!--                  :error="v$.modelPrivate.location.country.$errors.map( err => { return err.$message }).join()"-->
                </div>
                <div class="col-lg-4">
                  <base-input
                      alternative=""
                      :label="$t('Postal code')"
                      :placeholder="$t('Postal code')"
                      input-classes="form-control-alternative"
                      v-model="modelPrivate.location.postalCode"
                 />
<!--                  :valid="!v$.modelPrivate.location.postalCode.$invalid"-->
<!--                  :error="v$.modelPrivate.location.postalCode.$errors.map( err => { return err.$message }).join()"-->

                </div>
                <div class="col-lg-6">

                </div>
              </div>
              </div>
        </template>
              <div class="row">
                <div class="col align-self-end pt-3">

                  <submit-button @clicked="updateProfile()" style="float: right;" :alertMessage="errorMessage" :state="loadingProcess" size="md" type="primary">{{ $t("Update") }}</submit-button>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SubmitButton from "@/components/SubmitButton";
import FirebaseFunctionsHelper from "@/plugins/firebaseFunctionsHelper";
import {email, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import { useToast } from "vue-toastification";
import {store} from "@/store";
export default {
  name: "user-profile",
  components: {BaseButton, BaseHeader, SubmitButton},
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      modelPrivate:{
        email: {
          required,
          email
        },
        location: {
          street: {
          },
          city: {
          },
          country: {
          },
          postalCode: {
          },
        }
      },
      model: {
        firstName: {
          required
        },
        lastName: {
          required
        },
      }
    }
  },
  data() {
    return {
      staticCountry: "Cyprus",
      loadingProcess: "",
      errorMessage: "",
      genderOptions: [
        {value: "", text: "Select Gender"},
        {value: "MALE", text: this.$t("Male")},
        {value: "FEMALE", text: this.$t("Female")},
        {value: "OTHER", text: this.$t("Other")}
      ],
      cities: ["LIMASSOL", "NICOSIA", "LARNACA", "PAPHOS", "FAMAGUSTA"],
      modelPrivate: {
        email: null,
        createdAt: null,
        dateOfBirth: null,
        gender: "",
        role: "",
        location: {
          street: "",
          street2: "",
          postalCode: "",
          country: "CY",
          city: ""
        }
      },
      model: {
        email: "onid@ddas.sss",
        contactNumber: "",
        firstName: "",
        lastName: "",
  },
    };
  },
  async mounted() {
    await this.getUser()
  },
  methods: {
    async getUser() {
      let self = this;
      if (this.$store.state.user.data) {
        // eslint-disable-next-line no-prototype-builtins
        this.model.email = this.$store.state.user.data.hasOwnProperty("email") ? this.$store.state.user.data.email : "";
        this.model.firstName = this.$store.state.user.data.firstName;
        this.model.lastName = this.$store.state.user.data.lastName;
      }
      if (this.$store.state.user.private) {
        if(this.$store.state.user.private.location) {
          this.modelPrivate.location = this.$store.state.user.private.location;
        }
        this.modelPrivate.gender = this.$store.state.user.private.gender;
      }

      await this.$store.dispatch("fetchUserPrivate").then((data) => {
        if (data) {
          self.modelPrivate = data;
          // eslint-disable-next-line no-prototype-builtins
          if(data.hasOwnProperty("location") && data.location === null) {
            self.modelPrivate.location = {
              street: "",
              street2: "",
              postalCode: "",
              country: "CY",
              city: ""
            }
          }
        }
      });
    },
    updateProfile() {
      this.v$.modelPrivate.email.$touch();
      this.v$.model.$touch();
      if (this.v$.modelPrivate.email.$invalid || this.v$.model.$invalid) {
        return;
      }

        this.loadingProcess = 'processing'
        let data = {
          email: this.modelPrivate.email,
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          gender: this.modelPrivate.gender,
          location: this.modelPrivate.location
        }
        const toast = useToast();
        return FirebaseFunctionsHelper.callFunction(
            "updateUserProfile",
            // eslint-disable-next-line no-undef
            data
        ).then(async (result) => {
          // eslint-disable-next-line no-prototype-builtins
          if(result && result.hasOwnProperty("message")){
            this.loadingProcess = ''
            toast.error("There was a problem! "+ result.message, { timeout: false });
            return
          }
          toast.success("Successfully Updated!", { timeout: 1000 });
          this.loadingProcess = ''
          await store.dispatch("fetchUser", {uid: this.$store.state.user.claims.user_id});

          return result;
        }).catch((error) => {
          this.loadingProcess = "";
          this.errorMessage = error.message
          toast.error("There was an error!" + error.message, { timeout: false });
          console.log(error)
        });
    }
  }
};
</script>
<style></style>
