import {store} from "@/store";

export function getStatusClass(val) {
  // type (primary|info|danger|default|warning|success)
  switch (val) {
    case "VERIFIED":
      return "success";
    case "PENDING":
      return "warning";
    default:
      return "danger";
  }
}

export function getValueOrNull(obj, prop) {
    return (obj) ? Object.prototype.hasOwnProperty.call(obj, prop) ? obj[prop] : null : null
}

export function getLocationString(location, extraInfo = true) {
    let locationString = ""

    if (getValueOrNull(location, "street")) {
        locationString += location["street"]
    }
    if (extraInfo && getValueOrNull(location, "street2") && location["street2"] !== "" && location["street2"] !== null) {
        locationString += ", " + location["street2"]
    }
    if (getValueOrNull(location, "city")) {
        locationString += ", " + this.$t(location["city"])
    }
    if (extraInfo && getValueOrNull(location, "postalCode")) {
        locationString += ", " + location["postalCode"]
    }
    if (getValueOrNull(location, "country")) {
        locationString += ", " + this.$t(location["country"])
    }

    return locationString
}

export function getStatusIconClass(val) {
    // type (primary|info|danger|default|warning|success)
    switch (val) {
        case "VERIFIED":
            return "fa fa-check";
        case "PENDING":
            return "fa fa-clock";
        default:
            return "fa fa-times";
    }
}

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export async function getCompanyTypes() {
  let companyTypes = {}
  return await store
      .dispatch("fetchRemoteConfig", {value: "services_default_data"})
      .then((data) => {
        const uniqueCompanyTypes = Array.from(new Set(data.map(a => a.companyType)))
            .map(companyType => {
              return data.find(a => a.companyType === companyType)
            })
        uniqueCompanyTypes.forEach(type => {
          companyTypes[type.companyType] = type.categoryName
        })
        return companyTypes;
      });
}

export function convertMinsToHrsMins(mins, longText = true) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;


    if(h === 1){
        if(m > 0) {
            if(longText){
                return `${h+ ' '+ this.$t("hour") } ${m+ ' '+ this.$t("minutes")}`;
            } else {
                return `${h+ this.$t("h") } ${m+ this.$t("m")}`;
            }

        } else {
            return `${h+ ' '+ this.$t("hour") }`;
        }
    }

    else if (h > 1) {
        if(m > 0) {
            if(longText){
                return `${h+ ' '+ this.$t("hours") } ${m+ ' '+ this.$t("minutes")}`;
            } else {
                return `${h+ this.$t("h") } ${m+ this.$t("m")}`;
            }

        }

        if(longText){
            return `${h + ' ' + this.$t("hours") }`;
        } else {
            return `${h+ this.$t("h") }`;
        }
    } else {
        if(longText){
            return m + ' '+ this.$t("minutes");
        } else {
            return m + ' '+ this.$t("m");
        }

    }
}

const tooltipDirective = (app) => {
    app.directive("tooltip", {
        mounted(el, binding) {
            init(el, binding);
        },
        updated(el, binding) {
            init(el, binding);
        }
    });
};

function init(el, binding) {
    let position = binding.arg || "top";
    let tooltipText = binding.value || "Tooltip text";
    el.setAttribute("position", position);
    el.setAttribute("tooltip", tooltipText);
}

export default tooltipDirective;

export function setTitle(title) {
    document.title = title;
    document.querySelector('[property="og:title"]').setAttribute('content', title);
    document.querySelector('[name="twitter:title"]').setAttribute('content', title);
}

export function setDescription(description) {
    document.querySelector('[name="description"]').setAttribute('content', description);
    document.querySelector('[property="og:description"]').setAttribute('content', description);
    document.querySelector('[name="twitter:description"]').setAttribute('content', description);
}

export function setImage(image) {
    document.querySelector('[property="og:image"]').setAttribute('content', image);
    document.querySelector('[name="twitter:image"]').setAttribute('content', image);
}