<template>
  <div class="row justify-content-center" style="width: 100%; margin: 0 !important;">
    <div class="col-lg-5 col-md-7 mt-3" style="width: 100%">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4 mt-4">
            <h2>Login</h2>
          </div>
          <form role="form">
            <base-input
                type="text" pattern="\d*"
                formClasses="input-group-alternative mb-3 phone-input"
                style="display: flex;border-radius: 0;"
                :placeholder="$t('Phone Number')"
                :disabled="codeSent"
                v-model="phoneNumber"
            >
              <template v-slot:addonLeft>
                <i class="fa fa-phone pr-2"></i>
                <select :disabled="codeSent" class="form-control form-control-alternative" style="padding: 0;height: 23px;" v-model="countryCode">
                  <option :key="country.code" v-for="country in countryCodes" :value="country.code"> {{ isoCountryCodeToFlagEmoji(country.iso) }} {{country.iso}} {{country.code}}</option>
                </select>
              </template>
              <template v-if="codeSent" v-slot:addonRight>
                <base-button size="sm" class="p-0" type="link" @click="resetAuthForm" >Edit </base-button>
              </template>
            </base-input>

            <label class="ml-1 text-red text-sm" v-if="isPhoneValid === false">{{ $t("Must be a valid phone number") }}</label>
            <base-input
                type="text"
                pattern="\d*"
                formClasses="input-group-alternative mb-3"
                v-if="codeSent"
                :placeholder="$t('Verification Code')"
                addon-left-icon="fa fa-key"
                v-model="code"
            >
            </base-input>
            <base-alert v-if="showAlert" :type="alertType" dismissible>
              <strong></strong> {{$t(alertMessage)}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>

<!--            <base-checkbox class="custom-control-alternative">-->
<!--              <span class="text-muted">Remember me</span>-->
<!--            </base-checkbox>-->
            <div class="text-center">
              <base-button v-if="!codeSent" @click="getCode" :disabled="sendingCodeStatus" type="primary" >
                <template v-if="sendingCodeStatus">
                  <span class="fas fa-cog fa-spin" role="status" aria-hidden="true"></span>
                </template>
                <template v-else> {{ $t("Get verification code") }} </template>
              </base-button>
              <base-button v-else type="primary" @click="signIn" :disabled="code.length !== 6 || signInLoader" class="mt-4 mb-2">
                <template v-if="signInLoader">
                  <span class="fas fa-cog fa-spin" role="status" aria-hidden="true"></span>
                </template>
                <template v-else>
                  {{ $t("Submit Code") }}
                </template>
              </base-button>
            </div>
            <div id="recaptcha-container"></div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
<!--          <a href="#" class="text-light"><small>Forgot password?</small></a>-->
        </div>
        <div class="col-6 text-right">
<!--          <router-link to="/register" class="text-light"-->
<!--            ><small>Create new account</small></router-link-->
<!--          >-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import BaseButton from "@/components/BaseButton";
// import useVuelidate from "@vuelidate/core";
// import {helpers, required} from "@vuelidate/validators";
import {store} from "@/store";
import { isValidNumber, getCountries, getCountryCallingCode } from "libphonenumber-js"

export default {
  name: "login",
  components: {BaseButton},
  props: {
    beforeRoute: {
      default: null,
      type: String,
      description: "The route to navigate after login"
    }
  },
  // setup: () => ({ v$: useVuelidate() }),
  // validations () {
  //   return {
  //     phoneNumber: {
  //       required,
  //       mustHaveCyPrefix: helpers.withMessage(this.$t('Phone number be must be a valid CY number.'), helpers.regex(/^[0-9]{8}$/)),
  //     },
  //   }
  // },
  watch:{
    "phoneNumber": function (newVal) {
      this.isPhoneValid = isValidNumber(this.countryCode+newVal)
    },
    "countryCode": function (newVal) {
      this.isPhoneValid = isValidNumber(newVal+this.phoneNumber)
    }
  },
  data() {
    return {
      countryCodes: [],
      countryCode: "+357",
      isPhoneValid: null,
      showAlert: false,
      alertType: "",
      alertMessage: "",
      signInLoader: false,
      sendingCodeStatus: false,
      codeSent: false,
      phoneNumber: "",
      code: "",
      recaptchaVerifier: null,
      confirmationResult: null,
      // showModal: false
    };
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("response", response);
        },
      },
      getAuth(this.fireApp)
    );

    let countries = getCountries()

    countries.forEach( country => {
      let code = getCountryCallingCode(country)
      console.log("code", code)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods: {
    isoCountryCodeToFlagEmoji(country) {
      return String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 0x1F1A5));
    },
    resetModal() {
      this.phoneNumber = "";
      this.codeSent = false;
      this.showAlert = false
      this.clearAlert();
    },
    initAlert(type, message) {
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    clearAlert() {
      this.showAlert = false;
      this.alertType = "";
      this.alertMessage = "";
    },
    getCode() {
      let self = this;
      this.clearAlert();
      let userPhone = ""

      // this.v$.phoneNumber.$touch();
      if (this.isPhoneValid === true) {
        userPhone = this.countryCode+this.phoneNumber
      } else {
        this.isPhoneValid = false
        console.log("Invalid phone")
        return
      }

      this.sendingCodeStatus = true;
      let auth = getAuth(this.fireApp);

      signInWithPhoneNumber(auth, userPhone, this.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          self.codeSent = true;
          self.initAlert("info", "The 6-digit code has been sent to the mobile number given.");
          this.sendingCodeStatus = false;
          self.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          this.sendingCodeStatus = false;
          let message = error.message;
          if(error.code === "auth/invalid-phone-number") {
            message = "The phone number given is not valid.";
          }
          self.initAlert("danger", message);
          // Error; SMS not sent
        });
    },
    resetAuthForm(){
      this.codeSent = false
      this.showAlert = false
    },
    signIn() {
      let self = this;
      this.clearAlert()
      this.signInLoader = true;
      this.confirmationResult
        .confirm(this.code)
        .then(async (result) => {
          // User signed in successfully.
          const user = result.user;
          await store.dispatch("fetchUser", user);
          // await store.dispatch("fetchClient", {companyId: self.$route.params.id, userId: user.uid});
          self.resetModal();
          // Send user to previous path
          if (this.beforeRoute) {
            await this.$router.push({path: this.beforeRoute});
          } else {
            await this.$router.push({path: '/'});
          }
          // ...
        })
        .catch((error) => {
          this.signInLoader = false;
          // User couldn't sign in (bad verification code?)
          let message = error.message;
          if (error.code === "auth/invalid-verification-code") {
            message = "Verification code is invalid.";
          }
          self.initAlert("danger", message);
        });
    },
  },
};
</script>
<style></style>
