<template>
  <div>
    <h3>{{$t("Working Hours")}}</h3>
    <table class="font-weight-400">
      <template v-for="day in days">

        <template v-if="whPrettified[day]">
          <tr :class="{'font-weight-bold': (today.toUpperCase() === day)}" v-for="(hourSet, index) in whPrettified[day]" v-bind:key="hourSet">
            <td style="width:130px" v-if="index === 0">{{$t(day)}}</td>
            <td v-else></td>
            <td>{{hourSet}}</td>
          </tr>
        </template>
        <template v-else>

          <tr :class="{'font-weight-bold': (today.toUpperCase() === day)}" v-bind:key="day">
            <td>{{$t(day)}}</td>
            <td>{{ $t("Closed") }}</td>
          </tr>
        </template>
      </template>

    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "WorkingHours",
  data() {
    return {
      whPrettified: {},
      today: moment().locale(this.$store.state.locale).format('dddd'),
      days: [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ]
    }
  },
  props:{
    workingHours: {
      type: Object,
      default: null
    }
  },
  watch:{
    "workingHours": function (newVal) {
        this.calculateWorkingHours(newVal);
    }
  },
  mounted() {
    this.calculateWorkingHours(this.workingHours);
  },
  methods: {
    calculateWorkingHours(newVal){
      this.whPrettified = {}
      let loopingDay = ""
        newVal.forEach((hr) => {
          let wh = hr.split("-");

            if (loopingDay !== wh[0]) {
              loopingDay = wh[0]
              this.whPrettified[wh[0]] = []
            }
            this.whPrettified[wh[0]].push(wh[1] + " - " + wh[2]);
        });
    }
  }
}
</script>

<style scoped>

</style>