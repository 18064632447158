<template>
  <div>
  <div class="col companyInfo p-0 pt-2">

      <span class="badge bg-blue text-white mr-2"  v-if="company.serviceGender.hasOwnProperty('UNISEX')"><i class="fa fa-users mr-1"></i> {{$t("For everyone")}}</span>
    <span class="badge bg-pink text-white mr-2" v-else-if="company.serviceGender.hasOwnProperty('MEN') && company.serviceGender.hasOwnProperty('WOMEN') && company.serviceGender.hasOwnProperty('CHILDREN')"><i class="fa fa-users mr-1"></i> {{$t("For women & men")}}</span>
    <span class="badge bg-pink text-white mr-2" v-else-if="company.serviceGender.hasOwnProperty('MEN') && company.serviceGender.hasOwnProperty('WOMEN')"><i class="fa fa-users mr-1"></i> {{$t("For women & men")}}</span>
    <span class="badge bg-pink text-white mr-2" v-else-if="company.serviceGender.hasOwnProperty('MEN') && company.serviceGender.hasOwnProperty('CHILDREN')"><i class="fa fa-users mr-1"></i> {{$t("For men and children")}}</span>
    <span  class="badge bg-pink text-white mr-2" v-else-if="company.serviceGender.hasOwnProperty('WOMEN') && company.serviceGender.hasOwnProperty('CHILDREN')"><i class="fa fa-users mr-1"></i> {{$t("For women and children")}}</span>
    <span class="badge bg-pink text-white mr-2" v-else-if="company.serviceGender.hasOwnProperty('WOMEN')"><i class="fa fa-female mr-1"></i> {{$t("For women")}}</span>
    <span class="badge bg-blue text-white mr-2" v-else-if="!company.serviceGender.hasOwnProperty('UNISEX') && company.serviceGender.hasOwnProperty('MEN')"><i class="fa fa-male mr-1"></i> {{$t("For men")}}</span>
    <span class="badge bg-blue text-white mr-2" v-else-if="!company.serviceGender.hasOwnProperty('UNISEX') && company.serviceGender.hasOwnProperty('CHILDREN')"><i class="fa fa-child mr-1"></i> {{$t("For children")}}</span>

  </div>
  </div>
</template>

<script>
export default {
  name: "ExtraInfo",
  props:{
    company: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
.companyInfo span {
  white-space: nowrap;
  padding-right: 10px;

}
</style>