<template>
  <div>
  <div class="col companyExtras p-0">
    <span v-if="bookingSettings.hasOwnProperty('loyaltyProgramEnable') && bookingSettings.loyaltyProgramEnable" class="badge bg-primary text-white mr-2">
      <tooltip :tooltip-text="$t('With every appointment the company is rewarding you with loyalty points which you can spend to book an appointment for free ')" position="top">
        <i class="fa fa-users mr-1"></i> {{$t("Loyalty Rewards")}} {{bookingSettings.hasOwnProperty('loyaltyPercentage') ? bookingSettings.loyaltyPercentage : ''}}%
      </tooltip>
    </span>
    <span v-if="bookingSettings.hasOwnProperty('autoApprove') && bookingSettings.autoApprove" class="badge bg-primary text-white mr-2">
      <tooltip :tooltip-text="$t('Your appointments are automatically confirmed.')" position="top">
          <i class="fa fa-clock mr-1" ></i>
         {{$t("Instant confirmation")}}
      </tooltip>
    </span>
    <span v-if="bookingSettings.hasOwnProperty('smsNotificationsEnabled') && bookingSettings.smsNotificationsEnabled" class="badge bg-primary text-white">
      <tooltip :tooltip-text="$t('A reminder text message will be send to your mobil phone a day before every appointment.')" position="top">
          <i class="fa fa-sms mr-1"></i>{{$t("SMS Reminder")}}
      </tooltip>
    </span>

  </div>
  </div>
</template>

<script>
import Tooltip from "@/views/Components/Tooltip";
export default {
  name: "ExtraFeatures",
  components: {Tooltip},
  props:{
    bookingSettings: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
.companyExtras span {
  white-space: nowrap;
}
</style>