<template>
  <div class="home">
<!--        <img alt="Vue logo" src="../assets/logo.png" />-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App" />-->
    <!--    <hero></hero>-->
<!--    <div class="row align-items-center justify-content-xl-between" style="margin: 0">-->
<!--      <h1-->
<!--        style="-->
<!--          font-size: 45px;-->
<!--          width: 100%;-->
<!--          font-family: 'Pacifico', cursive;-->
<!--          /*background-image: url(/img/icons/safari-pinned-tab.svg);*/-->
<!--          /*background-size: cover;*/-->
<!--          /*background-attachment: fixed;*/-->
<!--        "-->
<!--        class="p-4 align-items-center text-center"-->
<!--      >-->
<!--        ~ Best Features ~-->
<!--      </h1>-->
<!--      <feature-->
<!--        bgColor="bg-gradient-purple"-->
<!--        :isDark="true"-->
<!--        :reversed="true"-->
<!--        src="https://res.cloudinary.com/imajin/image/upload/v1572099963/mobile/phone-left_mdthbj.png"-->
<!--        title="Remote Services"-->
<!--        description="We want our partners to be fully flexible. That is why we introduced the Remote Services, this simply means that you can provide services at your clients’ location"-->
<!--      />-->
      <download-apps></download-apps>

<!--      <feature-->
<!--        :isDark="true"-->
<!--        bgColor="bg-gradient-blue"-->
<!--        :reversed="false"-->
<!--        src="https://res.cloudinary.com/imajin/image/upload/v1572099963/mobile/phone-left_mdthbj.png"-->
<!--        title="No Show Penalty"-->
<!--        description="With this engine we will be monitoring your clients for no show appointments. A penalty will be applied for malicious or irresponsible clients in order to save you significant space in you calendar. "-->
<!--      />-->
<!--      <action-box-with-photo-->
<!--        bgColor="bg-gradient-green"-->
<!--      ></action-box-with-photo>-->
<!--      <feature-->
<!--        :isDark="true"-->
<!--        bgColor="bg-gradient-orange"-->
<!--        :reversed="true"-->
<!--        src="https://res.cloudinary.com/imajin/image/upload/v1572099963/mobile/phone-left_mdthbj.png"-->
<!--        title="SMS Reminders"-->
<!--        description="Using SMS we make sure your clients will get notified of their upcoming appointment. Reduce no-shows with email or SMS notifications for your clients."-->
<!--      />-->
<!--      <feature-->
<!--        bgColor="bg-gradient-purple"-->
<!--        :isDark="true"-->
<!--        :reversed="false"-->
<!--        src="https://res.cloudinary.com/imajin/image/upload/v1572099963/mobile/phone-left_mdthbj.png"-->
<!--        title="SMS Notifications"-->
<!--        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."-->
<!--      />-->

<!--      <numbered-steps></numbered-steps>-->
<!--    </div>-->
<!--    <section class="section section-lg section-shaped overflow-hidden my-0">-->
<!--      <div class="shape shape-style-1 shape-default shape-skew">-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--      </div>-->
<!--      <div class="container shape-container py-0 pb-5">-->
<!--        <div class="row row-grid justify-content-between align-items-center">-->
<!--          <div class="col-lg-6">-->
<!--            <h3 class="display-3 text-white">-->
<!--              A beautiful Design System-->
<!--              <span class="text-white">completed with examples</span>-->
<!--            </h3>-->
<!--            <p class="lead text-white">-->
<!--              The Design System comes with four pre-built pages to help you get-->
<!--              started faster. You can change the text and images and you're good-->
<!--              to go. More importantly, looking at them will give you a picture-->
<!--              of what you can built with this powerful Bootstrap 4 Design-->
<!--              System.-->
<!--            </p>-->
<!--&lt;!&ndash;            <div class="btn-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;              <router-link to="/login" class="btn btn-success">&ndash;&gt;-->
<!--&lt;!&ndash;                App Store&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;              <router-link to="/register" class="btn btn-success">&ndash;&gt;-->
<!--&lt;!&ndash;                Play Store&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="col-lg-5 mb-lg-auto">-->
<!--            <div class="transform-perspective-right">-->
<!--              <card-->
<!--                class="border-0"-->
<!--                type="secondary"-->
<!--                shadow-->
<!--                body-classes="px-lg-5 py-lg-5"-->
<!--                header-classes="pb-5 bg-white"-->
<!--              >-->
<!--                <template slot="header">-->
<!--                  <div class="text-muted text-center mb-3">-->
<!--                    <small>Sign in with</small>-->
<!--                  </div>-->
<!--                  <div class="btn-wrapper text-center">-->
<!--                    <base-button type="neutral">-->
<!--                      <img slot="icon" src="img/icons/common/github.svg" />-->
<!--                      Github-->
<!--                    </base-button>-->

<!--                    <base-button type="neutral">-->
<!--                      <img slot="icon" src="img/icons/common/google.svg" />-->
<!--                      Google-->
<!--                    </base-button>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <template>-->
<!--                  <div class="text-center text-muted mb-4">-->
<!--                    <small>Or sign in with credentials</small>-->
<!--                  </div>-->
<!--                  <form role="form">-->
<!--                    <base-input-->
<!--                      alternative-->
<!--                      placeholder="Email"-->
<!--                      addon-left-icon="ni ni-email-83"-->
<!--                    >-->
<!--                    </base-input>-->
<!--                    <base-input-->
<!--                      alternative-->
<!--                      type="password"-->
<!--                      placeholder="Password"-->
<!--                      addon-left-icon="ni ni-lock-circle-open"-->
<!--                    >-->
<!--                    </base-input>-->
<!--                    <base-checkbox> Remember me </base-checkbox>-->
<!--                    <div class="text-center">-->
<!--                      <base-button type="primary" class="my-4"-->
<!--                        >Sign In</base-button-->
<!--                      >-->
<!--                    </div>-->
<!--                  </form>-->
<!--                </template>-->
<!--              </card>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </section>-->
<!--    <div class="row align-items-center justify-content-xl-between" style="margin: 0">-->
<!--        <company-types></company-types>-->
<!--      </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import Feature from "../components/Feature";
//
import DownloadApps from "../components/DownloadApps";
export default {
  name: "Home",
  components: {
    DownloadApps,
    // Feature,
  },
};
</script>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
</style>

