<template>
    <section class="section-hero section-shaped bg-gradient-primary my-0" style="height: calc(100vh - 196px);">
        <div class="shape shape-style-1 shape-primary">
            <span class="span-150"></span>
            <span class="span-50"></span>
            <span class="span-50"></span>
            <span class="span-75"></span>
            <span class="span-100"></span>
            <span class="span-75"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
        </div>
        <div class="container shape-container d-flex">
            <div class="col px-0">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-md-7 text-left pt-lg pt-2">
                      <div class="fof">
                      <h1 style="font-size: 50px" class="fof text-white">{{title}}</h1>
                        </div>
                        <h2 class="lead text-white mt-4 mb-5">{{descriptionText}}</h2>
                        <div class="btn-wrapper">

<!--                            <base-button @click="showModal()">Contact Us!</base-button>-->
<!--                          <modal :show="showModalBool" @close="showModalBool = false" >-->
<!--                            <template v-slot:header >Contact Us</template>-->
<!--                            <div>-->
<!--                              <div class="col-lg-12">-->
<!--                                <base-input-->
<!--                                    alternative=""-->
<!--                                    label="Name"-->
<!--                                    placeholder="Name"-->
<!--                                    input-classes="form-control-alternative"-->
<!--                                    v-model="form.name"-->
<!--                                    :valid="!v$.form.name.$invalid"-->
<!--                                    :error="v$.form.name.$errors.map( err => { return err.$message }).join()"-->
<!--                                />-->
<!--                                <base-input-->
<!--                                    alternative=""-->
<!--                                    type="email"-->
<!--                                    label="Email"-->
<!--                                    placeholder="Email"-->
<!--                                    input-classes="form-control-alternative"-->
<!--                                    v-model="form.email"-->
<!--                                    :valid="!v$.form.email.$invalid"-->
<!--                                    :error="v$.form.email.$errors.map( err => { return err.$message }).join()"-->
<!--                                />-->
<!--                              </div>-->
<!--                              <div class="col-lg-12" :class="[{'has-danger' : v$.form.description.$invalid}]">-->
<!--                                <base-input alternative="" label="Tell us what went wrong">-->
<!--                      <textarea-->
<!--                          rows="4"-->
<!--                          class="form-control form-control-alternative"-->

<!--                          placeholder="A few of what went wrong and how we can help"-->
<!--                          v-model="form.description"-->
<!--                      >-->
<!--                      </textarea>-->
<!--                                  <div v-if="v$.form.description.$invalid" class="text-danger invalid-feedback" style="display: block;">{{v$.form.description.$errors.map( err => { return err.$message }).join()}}</div>-->
<!--                                </base-input>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <template v-slot:footer>-->
<!--&lt;!&ndash;                              <base-button type="danger" @click="showModalBool = false">Close</base-button>&ndash;&gt;-->
<!--                              <base-button @click="submitContactUs()" >Submit</base-button>-->
<!--                            </template>-->

<!--                          </modal>-->
                        </div>
                    </div>
                </div>
<!--                <div class="row align-items-center justify-content-around stars-and-coded">-->
<!--                    <div class="col-sm-4">-->
<!--                        <span class="text-white alpha-7 ml-3">Star us on</span>-->
<!--                        <a href="https://github.com/creativetimofficial/argon-design-system" target="_blank" title="Support us on Github">-->
<!--                            <img src="img/brand/github-white-slim.png" style="height: 22px; margin-top: -3px">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="col-sm-4 mt-4 mt-sm-0 text-right">-->
<!--                        <span class="text-white alpha-7">Coded by</span>-->
<!--                        <a href="https://www.creative-tim.com" target="_blank" title="Creative Tim - Premium Bootstrap Themes and Templates">-->
<!--                            <img src="img/brand/creativetim-white-slim.png" class="ml-3" style="height: 30px;">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </section>
</template>
<script>
// import BaseButton from "@/components/BaseButton";
// import Modal from "@/components/Modal";
// import BaseInput from "@/components/BaseInput";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
export default {
  name: "CustomHero",
  // components: {BaseInput, Modal, BaseButton},
  props: {
    title: {
      type: String,
      default: "Error 404",
      description: "The discounted price.",
    },
    descriptionText: {
      type: String,
      default: "The page you are looking for does not exist.",
      description: "The discounted price.",
    }
  },
  data(){
    return {
      form: {
        description: "",
        email: "",
        name: ""
      },
      showModalBool:false
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      form: {
        name: {
          required
        },
        email: {
          required,
          email
        },
        description: {
          required
        }
      }
    }
  },
  methods: {
    submitContactUs(){
      this.v$.$touch()

      if (this.v$.form.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
    },
    showModal() {
      this.showModalBool = true
      if(this.$store.state.user.data) {
        this.form.name = this.$store.state.user.data.firstName + ' ' + this.$store.state.user.data.lastName
        if(this.$store.state.user.data.email) {
          this.form.email = this.$store.state.user.data.email
        }
      }
    }
  }
};
</script>
<style>
.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #ffffff;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}


</style>
