<template>
  <div id="app">

    <router-view></router-view>

  </div>
</template>
<script>
export default {
  computed: {
    exclude () {
      if (process.env.NODE_ENV === 'production') {
        return ''
      }
      return /.+/
    }
  }
}
</script>