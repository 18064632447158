<template>
    <div style="    position: fixed;
    width: 100%;
    z-index: -1;
    height: 100%;
    opacity: 0.2;
    left: 0;
    top: 0;">
<!--      <div class="col-md-4 text-left p-5 business-info-screen-container-left" style="float:left;">-->
<!--        <image style="margin:30px 0;" width="150" src="https://www.pngmart.com/files/4/Pepsi-Logo-Transparent-PNG.png" />-->


<!--          <span style="height: 94px;display: block;"></span>-->
<!--          <div class="business-info-screen-container-left-booking-container">-->
<!--            <button style="background:teal;color:white;width: 90%;" class="btn p-3 pl-4 pr-4"> Book an appointment</button>-->
<!--          </div>-->
<!--      </div>-->

        <div class="business-info-screen-container-right" :style="'background-image: url('+photo+'), url('+photo+'); background-size: cover, 66%;'">


      </div>
    </div>
</template>

<script>
export default {
  name: "CompanyPhoto",
  props:{
    photo: {
      default: '',
      type: String
    }
  }
}
</script>

<style scoped>
.business-info-screen-container-right {
  background-color: #f8f8f8;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: inline-block;
  margin-bottom: -7px;
}

@media screen and (max-width: 768px) {
  .business-info-screen-container-right {
    display: none;
  }

  .business-info-screen-container-left-booking-container {
    width: 100% !important;
    padding: 16px !important;
    left: 0;
    right: 0;
    max-width: calc(100%);
  }
}
</style>