import { createRouter, createWebHistory } from "vue-router";

// no auth views
import QuickBook from "../views/QuickBook/QuickBook.vue";
import Home from "../views/Home.vue";
// import AuthLayout from "@/layout/AuthLayout";
import TopLayout from "@/layout/TopLayout";
import Lost from "../views/404.vue";
import UserProfile from "../views/UserProfile"
import Login from "../views/Login.vue";
import UserAppointments from "@/views/UserAppointments/UserAppointments";
import UserAppointment from "@/views/UserAppointments/UserAppointment";
const routes = [
  {
    path: "/",
    component: TopLayout,
    children: [
      {
        path: "/",
        redirect: { name: 'UserAppointments'},
        name: "home",
        components: { default: Home },
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        props: true
      },
      {
        path: "/quick-book/:id",
        name: "QuickBook",
        components: { default: QuickBook },
      },
      {
        path: "/c/:id",
        name: "Company",
        components: { default: QuickBook },
      },
      {
        path: '/profile',
        name: "profile",
        meta: { requiresAuth: true },
        components: { default: UserProfile },
      },
      // {
      //   // /search/screens -> /search?q=screens
      //   path: '/a/:id',
      //   redirect: to => {
      //     // the function receives the target route as the argument
      //     // we return a redirect path/location here.
      //     return { name: 'appointment', params: {id: to.params.id}}
      //   },
      // },
        {
            path: '/appointments',
            name: "UserAppointments",
            meta: {requiresAuth: true},
            components: { default: UserAppointments },
        },
          {
            path: '/a/:id',
            name: "UserAppointment",
            meta: { requiresAuth: true },
            components: { default: UserAppointment },
            props: true,
          },
      {
        path: '/:pathMatch(.*)*',
        name: "lost",
        components: { default: Lost },
      },
      {
        path: '/error',
        name: "error",
        components: { default: Lost },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
