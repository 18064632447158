import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist'
import { getAuth } from "firebase/auth";
import { fireApp } from "@/main";
import {
  collection,
  collectionGroup,
  orderBy,
  doc,
  getDoc,
  getFirestore,
  getDocs,
  where,
  query,
  limit,
  startAt,
  startAfter
  // onSnapshot
} from "firebase/firestore";
import moment from "moment";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

function getDocReference(path, id) {
  let fireS = getFirestore(fireApp);
  return doc(collection(fireS, path), id);
}

function getDocEl(path, id) {
  let fireS = getFirestore(fireApp);
  return doc(fireS, path, id);
}

function getCollectionGroupReference(path) {
  let fireS = getFirestore(fireApp);
  return collectionGroup(fireS, path);
}

export const store = createStore({
  plugins: [new VuexPersistence().plugin],
  state: {
    locale: "en",
    system: {
      statistics: {},
      companies: [],
      systemUsers: [],
    },
    company: {},
    companyColor: null,
    companyColorIsLight: false,
    showLogin: false,
    user: {
      claims: null,
      loggedIn: false,
      data: null,
      private: null,
      clientData: null
    },
  },
  mutations: {
    SET_LOCALE (state, lang) {
      state.locale = lang
    },
    RESET_STATE(state) {
      state.user = {
        claims: null,
            loggedIn: false,
            data: null,
            private: null,
      };
      state.company = {};
    },
    SET_CLIENT(state, data) {
      state.user.clientData = data;
    },
    SET_LOGGED_IN(state, data) {
      state.user.loggedIn = data;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_USER_PRIVATE(state, data) {
      state.user.private = data;
    },
    SET_USER_CLAIMS(state, data) {
      state.user.claims = data;
    },
    SET_STATISTICS(state, data) {
      state.system.statistics = data;
    },
    GET_COMPANY(state, data) {
      state.company = data;
    },
    GET_BOOKING_SETTINGS(state, data) {
      state.bookkingSettings = data;
    },
    GET_COMPANY_STATISTICS(state, data) {
      state.companyStatistics = data;
    },
    SET_SYSTEM_USERS(state, data) {
      state.system.systemUsers = data;
    },
    SET_COMPANIES(state, data) {
      state.system.companies = data;
    },
  },
  getters: {},
  actions: {
    setLocale ({ commit }, lang) {
      commit('SET_LOCALE', lang)
    },
    async getClaims() {
      return await getAuth(fireApp)
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          return idTokenResult.claims;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
    isLoggedIn({ commit }) {
      if (this.state.user.loggedIn){
        return true;
      }
      let currUser = getAuth(fireApp).currentUser;
      commit("SET_LOGGED_IN", currUser != null);
      return currUser != null;
    },
    signOut({ commit }) {
      return getAuth(fireApp)
        .signOut()
        .then(() => {
          // console.log("logged out");
          commit("RESET_STATE");
          return true
        });
    },
    async fetchSystemStatistics({ commit }) {
      let systemRef = getDocReference("system", "statistics");
      await getDoc(systemRef).then((doc) => {
        if (doc) {
          let statistics = doc.data();
          commit("SET_STATISTICS", statistics);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchCompanyServices({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);

      let collectionRef = collection(
        fireS,
        "companies/" + companyId + "/services"
      );
      const docsQuery = query(
        collectionRef,
          orderBy('categoryPriority', 'asc'),
          orderBy('categoryId', 'asc'),
          orderBy('name', 'asc'),
          // orderBy('serviceLocation', 'asc'),
        where("serviceLocation", "in", [data.type, "BUSINESS_AND_MOBILE"]),
        where("status", "==", "ACTIVE"),
        where("isPrivate", "==", false),
        where("hasEmployees", "==", true),
        limit(20)
      );

      let docSnap = null
      let finalQuery
      if(data.servicesLastItem !== null) {
        docSnap = data.servicesLastItem
        finalQuery = query(docsQuery, startAt(docSnap))
      } else {
        finalQuery = docsQuery
      }


      return await getDocs(finalQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          const docData = doc.data();
          docData["id"] = doc.id;
          docsData.push(docData)
        });
        const lastVisible = docs.docs[docs.docs.length-1];
        return {items:docsData, servicesLastItem: lastVisible};
      });
    },
    async fetchCompany({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);
      let userQuery = doc(collection(fireS, "companies"), companyId);
      return await getDoc(userQuery).then((doc) => {
        if (doc) {
          let company = doc.data();
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty("id")) {
            return company;
          } else {
            commit("GET_COMPANY", company);
          }
        }
        return null;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchAvailableEmployees({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);

      let collectionRef = collection(
        fireS,
        "companies/" + companyId + "/employees"
      );
      let docsQuery = query(
        collectionRef
      );

      data.services.forEach( service => {
        docsQuery = query(
          docsQuery,
          where("serviceIds." + service.id, "==", true)
        );
      });

      docsQuery = query(docsQuery, where("status", "==", "ACTIVE"), limit(20))

      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          const docData = doc.data();
          docData["id"] = doc.id;
          docsData.push(docData)
        });
        return docsData;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchInvoice({ commit }, data) {
      let fireS = getFirestore(fireApp);
      let invoicesRef = collectionGroup(fireS, "invoices")
      //
      const docsQuery = query(
        invoicesRef,
        where("uid", "==", data.id),
        limit(1)
      );

      return await getDocs(docsQuery) .then((docs) => {
        if (docs.docs.length === 0){
          return null;
        }
        let doc = docs.docs[0];
        if (doc) {
          let invoiceData = doc.data();
          invoiceData["createdAt"] = moment(invoiceData["createdAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          if (data["status"] === "VERIFIED") {
            invoiceData["verifiedAt"] = moment(invoiceData["verifiedAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          } else if (data["status"] === "REJECTED"){
            invoiceData["rejectedAt"] = moment(invoiceData["rejectedAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          }

          return invoiceData;
        }
        return null
      });
    },
    async fetchInvoices() {
      let fireS = getFirestore(fireApp);
      let collectionRef = collectionGroup(fireS, "invoices");
      const docsQuery = query(
        collectionRef,
        orderBy("createdAt", "desc"),
        where("status", "in", ["VERIFIED", "PENDING", "CANCELED", "REJECTED"]),
        limit(20)
      );

      return await getDocs(docsQuery).then((docs) => {
        let invoices = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["createdAt"] = moment(data["createdAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          if (data["status"] === "VERIFIED") {
            data["verifiedAt"] = moment(data["verifiedAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          } else if (data["status"] === "REJECTED"){
            data["rejectedAt"] = moment(data["rejectedAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          }
          invoices.push(data);
        });
        return invoices;
      });
    },
    async fetchCompanyInvoices() {
      let fireS = getFirestore(fireApp);
      let companyId = this.state.user.claims.companyId;

      let collectionRef = collection(fireS, "companies/" + companyId + "/invoices");
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["VERIFIED", "PENDING", "CANCELED", "REJECTED"]),
        limit(20)
      );
      return await getDocs(docsQuery).then((docs) => {
        let invoices = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["createdAt"] = moment(data["createdAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          invoices.push(data);
        });
        return invoices;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchRemoteConfig({ commit }, data) {
      let remoteConfig = getRemoteConfig();
      remoteConfig.settings.minimumFetchIntervalMillis = 60000;
      return await fetchAndActivate(remoteConfig)
        .then(() => {
          let value = getValue(remoteConfig, data.value);

          return JSON.parse(value.asString())
        })
        .catch((err) => {
          console.log("fetchAndActivate", err);
        });
    },
    async fetchBookingSettings({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }
      let bookingSettingsDoc = getDocReference(
        "companies/" + companyId + "/companyPrivate",
        "bookingSettings"
      );
      return await getDoc(bookingSettingsDoc).then((doc) => {
        if (doc) {
          let bookingSettings = doc.data();
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty("id")) {
            return bookingSettings;
          } else {
            commit("GET_BOOKING_SETTINGS", bookingSettings);
            return bookingSettings;
          }
        }
      });
    },
    async fetchUserPrivate({ commit }) {
      if(this.state.user.claims === null){
        return null
      }
      let userPrivateQuery = getDocReference(
        "users/" +
          this.state.user.claims.user_id +
          "/userPrivate",
        "info"
      );

      return await getDoc(userPrivateQuery).then((doc) => {
        if (doc.exists()) {
          let userPriv = doc.data();

          commit("SET_USER_PRIVATE", userPriv);
          return userPriv;
        }
      });
    },
    async fetchSystemUsers({ commit }) {
      let fireS = getFirestore(fireApp);

      let collectionRef = collection(fireS, "systemUsers");
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["ACTIVE", "INACTIVE"]),
        limit(20)
      );
      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["name"] = data["firstName"] + " " + data["lastName"];
          docsData.push(data);
        });
        commit("SET_SYSTEM_USERS", docsData);
        return docsData;
      });
    },
    async fetchCompanies({ commit }) {
      let fireS = getFirestore(fireApp);

      let collectionRef = collection(fireS, "companies");
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["ACTIVE", "INACTIVE"]),
        limit(20)
      );

      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["createdAt"] = moment(data["createdAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
          docsData.push(data);
        });
        commit("SET_COMPANIES", docsData);
        return docsData;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchClient({ dispatch, commit }, data) {
      // eslint-disable-next-line no-unreachable
      let path = "companies/" + data.companyId + "/clients";
      let userQuery = getDocEl(
          path,
          data.userId
      );
       // onSnapshot(userQuery, (doc) => {
       let clientDoc = await getDoc(userQuery)

        if (clientDoc.exists()) {
          let client = clientDoc.data();
          commit("SET_CLIENT", client);
          return client
        }
        return null

    },
    async fetchUser({ dispatch, commit }, user) {

      if (user) {
        var claims = await dispatch("getClaims");

        let path = "users";


        let userQuery = getDocReference(
          path,
          user.uid
        );

        await getDoc(userQuery).then((doc) => {
          if (doc.exists()) {
            let userD = doc.data();
            commit("SET_USER", userD);
          }
        });
        commit("SET_USER_CLAIMS", claims);

      } else {
        commit("SET_USER", null);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getUserAppointment({ dispatch, commit }, ref) {
      let id = ""
      if (this.state.user.claims.user_id){
        id = this.state.user.claims.user_id
      } else {
        return null
      }
      let userAppointmentRef = getCollectionGroupReference("appointments");

      const userAppointmentQuery = query(
          userAppointmentRef,
          where('clientId', '==', id),
          where("bookingRef", '==', ref),
          limit(1));

      const documentsSnapshot = await getDocs(userAppointmentQuery);

        const docData = documentsSnapshot.docs[0].data();
        docData["id"] = documentsSnapshot.docs[0].id;
        if (docData["startTime"]) {
          docData["startTime"] = docData["startTime"].toDate()
          docData["mStartTime"] = moment(docData["startTime"])
              .locale(this.state.locale)
              .add(docData["travelTime"] ?? 0, 'minutes');

        }
        if(docData["createdAt"]){
          docData["mcreatedAt"] = moment(docData["createdAt"].toDate()).locale(this.state.locale).format("dddd, DD MMM YYYY [at] HH:mm a");
          docData["createdAt"] = moment(docData["createdAt"].toDate()).locale(this.state.locale).format("DD/MM/YYYY HH:mm");
        }
        if(docData["endTime"]){
          docData["endTime"] = moment(docData["endTime"].toDate()).locale(this.state.locale);
        }
        return docData
    },
    // eslint-disable-next-line no-unused-vars
    async getUserAppointments({ dispatch, commit }, data) {
      let id = ""
      if(data.userId){
        id = data.userId
      } else if (this.state.user.claims.user_id){
        id = this.state.user.claims.user_id
      } else {
        return null
      }
      let userAppointmentRef = getCollectionGroupReference("appointments");

      const userAppointmentsRef = query(
          userAppointmentRef,
          where('clientId', '==', id),
          orderBy('startTime', 'desc'),
          limit(10));
      let finalQuery


      if(data.lastItem) {
        finalQuery = query(userAppointmentsRef, startAfter(data.lastItem))
      } else {
        finalQuery = userAppointmentsRef
      }

      const querySnapshot = await getDocs(finalQuery);
      let docsData = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        docData["id"] = doc.id;
        if(docData["startTime"]) {
          docData["startTime"] = moment(docData["startTime"].toDate())
              .locale(this.state.locale)
              .add(docData["travelTime"] ?? 0, 'minutes');

          docData["mStartTime"] = moment(docData["startTime"].toDate())
              .locale(this.state.locale)
              .add(docData["travelTime"] ?? 0, 'minutes')
              .format("dddd, DD MMM YYYY [at] HH:mm a");

          docData["mcreatedAt"] = moment(docData["createdAt"].toDate()).locale(this.state.locale).format("dddd, DD MMM YYYY [at] HH:mm a");

        }
        docsData.push(docData)
      });

      var lastItem = null
      if (querySnapshot.docs.length > 0) {

        lastItem = querySnapshot.docs[querySnapshot.docs.length-1]
      }
      return {results: docsData, lastItem: lastItem}
    }
  },
  modules: {},
});
