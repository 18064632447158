import { store } from "../store/index";
import { createI18n } from "vue-i18n";
import en from "./en";
import gr from "./gr";
import ru from "./ru";

export default new createI18n({
  legacy: false,
  locale: store.state.locale,
  globalInjection: true,
  fallbackLocale: "en",
  messages: {
    en: en,
    gr: gr,
    el: gr,
    ru: ru,
  },
});
